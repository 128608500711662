import React from 'react';
import { useAppDispatch, useAppSelector } from '@app/hooks/reduxHooks';
import { useResponsive } from '@app/hooks/useResponsive';
import { BaseAvatar } from '@app/components/common/BaseAvatar/BaseAvatar';
import { Dropdown, MenuProps, Skeleton } from 'antd';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { FullNameStyle, Text, TypographyStyle, WrapperStyle } from './ProfileDropdown.styles';
import { doLogout } from '@app/store/slices/authSlice';
import { LogoutOutlined, UserOutlined } from '@ant-design/icons';

export const ProfileDropdown: React.FC = () => {
  const { isDesktop } = useResponsive();
  const { t } = useTranslation();

  const navigate = useNavigate();

  const user = useAppSelector((state) => state.user.user);

  const dispatch = useAppDispatch();

  const handleLogout = () => {
    navigate('/auth/login', { replace: true });
    dispatch(doLogout());
  };

  const handleProfile = () => {
    navigate('/profile');
  };

  const items: MenuProps['items'] = [
    {
      label: (
        <Text>
          <TypographyStyle>{t('profile.title')}</TypographyStyle>
        </Text>
      ),
      key: '0',
      icon: <UserOutlined />,
    },
    {
      type: 'divider',
    },
    {
      label: (
        <Text>
          <TypographyStyle>{t('header.logout')}</TypographyStyle>
        </Text>
      ),
      key: '1',
      icon: <LogoutOutlined />,
    },
  ];

  const onClick: MenuProps['onClick'] = ({ key }) => {
    if (key === '0') {
      handleProfile();
    }

    if (key === '1') {
      handleLogout();
    }
  };

  return user ? (
    <Dropdown menu={{ items, onClick }} trigger={['click']}>
      <WrapperStyle>
        {isDesktop ? (
          <FullNameStyle>{user.fullName}</FullNameStyle>
        ) : (
          <BaseAvatar src={user.avatar} alt="User" shape="circle" size={30} />
        )}
      </WrapperStyle>
    </Dropdown>
  ) : (
    <Skeleton.Input active size={'small'} />
  );
};
