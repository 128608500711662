import React, { useState, useEffect, useMemo } from 'react';
import styled from 'styled-components';
import { Checkbox, Button, Row, Col, Dropdown } from 'antd';
import { MoreOutlined } from '@ant-design/icons';

import { ReactComponent as PlusIcon } from '@app/assets/icons/plus.svg';
import { ReactComponent as DotsVerticalIcon } from '@app/assets/icons/dots-vertical.svg';

import BaseLayout from '@app/components/layouts/BaseLayout';
import { PageTitle } from '@app/components/common/PageTitle/PageTitle';
import { BaseButton } from '@app/components/common/BaseButton/BaseButton';
import { BaseInput } from '@app/components/common/inputs/BaseInput/BaseInput';
import DesignMakerModal from '@app/pages/DesignMaker/DesignMakerModal';
import CreateProductTemplateModal from '@app/components/ProductTemplates/CreateProductTemplateModal';
import { useResponsive } from '@app/hooks/useResponsive';

import { makerDataSelector, setMaker } from '@app/store/slices/makerSlice';

import { ListItemColor } from './ProductTemplates.styles';
import { WrapperDashboardStyle } from '@app/pages/DashboardPages/DashboardPage.styles';
import { BaseTypography } from '@app/components/common/BaseTypography/BaseTypography';
import { media } from '@app/styles/themes/constants';

import tshirtRedFront from '@app/assets/images/tshirt-red.png';
import { useAppDispatch, useAppSelector } from '@app/hooks/reduxHooks';

import { useNavigate } from 'react-router-dom';

import tshirtRed from '@app/assets/images/tshirt-red.png';
import tshirtRedBack from '@app/assets/images/back.png';
import groupImage from '@app/assets/images/group.png';

const dummyAreas = [
  {
    src: tshirtRed,
    imagesMaker: {
      image: {
        src: groupImage,
        with: null,
        height: null,
        top: 128,
        left: 223,
        rotate: null,
      },
      text: {
        content: '',
        with: null,
        height: null,
        top: null,
        left: null,
        rotate: null,
      },
    },
    type: 'dtg',
    area: {
      width: 160,
      height: 200,
      top: 100,
      left: 120,
    },
    position: 'front',
  },
  {
    src: tshirtRedBack,
    imagesMaker: {
      image: {
        src: groupImage,
        with: null,
        height: null,
        top: null,
        left: null,
        rotate: null,
      },
      text: {
        content: '',
        with: null,
        height: null,
        top: null,
        left: null,
        rotate: null,
      },
    },
    type: 'dtg',
    area: {
      width: 160,
      height: 200,
      top: 100,
      left: 120,
    },
    position: 'back',
  },
];

// Dummy data
const dummyData = [
  {
    productId: '1',
    productTemplateId: 1,
    title: 'Unisex classic tee Unisex classic tee Unisex classic tee Unisex classic tee Unisex classic tee ',
    color: 'Red',
    printing: 'DTG printing',
    size: ['M', 'L', 'XL', '2XL', '3XXL', '4XL', '5XL', '6XL'],
    printLocation: ['Front print', 'Back print'],
    image: tshirtRedFront,
    productStatus: 'active',
  },
  {
    productId: '2',
    productTemplateId: 2,
    title: 'Unisex classic tee',
    color: 'Red', // {colorCode, colorName}
    printing: 'DTG printing',
    size: ['M'],
    printLocation: ['Front print', 'Back print'],
    image: tshirtRedFront,
    productStatus: 'active',
  },
  {
    productId: '3',
    productTemplateId: 3,
    title: 'Unisex classic tee3',
    color: 'Red',
    printing: 'DTG printing',
    size: ['M'],
    printLocation: ['Front print', 'Back print', 'Left sleeve', 'Right sleeve'],
    image: tshirtRedFront,
    productStatus: 'active',
  },
  {
    productId: '4',
    productTemplateId: 4,
    title: 'Unisex classic tee4',
    color: 'Red',
    printing: 'DTG printing',
    size: ['XL', '2XL', '3XXL', '4XL'],
    printLocation: ['Front print'],
    image: tshirtRedFront,
    productStatus: 'active',
  },
  {
    productId: '5',
    productTemplateId: 5,
    title: 'Create new product',
    color: 'Green',
    printing: 'N/A',
    size: ['5XL', '6XL'],
    printLocation: ['Front print', 'Back print', 'Left sleeve'],
    image: tshirtRedFront,
    productStatus: 'active',
  },
];

const ProductTemplates = () => {
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [searchText, setSearchText] = useState('');
  const [viewMode, setViewMode] = useState('list'); // 'list' hoặc 'table'
  const [openTemplate, setOpenTemplate] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState({});

  console.log({ dropdownOpen });

  const navigate = useNavigate();

  const { isDesktop } = useResponsive();

  const dispatch = useAppDispatch();
  const { open } = useAppSelector(makerDataSelector);

  // Lọc dữ liệu dựa trên search
  const filteredData = useMemo(() => {
    return dummyData.filter(
      (item) =>
        item.title.toLowerCase().includes(searchText.toLowerCase()) ||
        item.printing.toLowerCase().includes(searchText.toLowerCase()) ||
        item.size.filter((data) => data.toLowerCase().includes(searchText.toLowerCase())),
    );
  }, [searchText]);

  // Xử lý chọn tất cả
  const onSelectAll = (e) => {
    if (e.target.checked) {
      const keys = filteredData.map((item) => item.productTemplateId);
      setSelectedRowKeys(keys);
    } else {
      setSelectedRowKeys([]);
    }
  };

  const items = [
    {
      label: <Text>Create to order</Text>,
      key: 'order',
    },
    {
      label: <Text>View template</Text>,
      key: 'view',
    },
    {
      label: <Text>Rename</Text>,
      key: 'rename',
    },
    {
      label: <Text>Download mockup</Text>,
      key: 'download',
    },
    {
      label: <Text>Duplicate template</Text>,
      key: 'template',
    },
    {
      label: <Text>Delete</Text>,
      key: 'delete',
    },
  ];

  // Xử lý chọn từng item
  // const onSelect = (record) => {
  //   const selectedKeys = [...selectedRowKeys];
  //   const index = selectedKeys.indexOf(record.productTemplateId);
  //   if (index === -1) {
  //     selectedKeys.push(record.productTemplateId);
  //   } else {
  //     selectedKeys.splice(index, 1);
  //   }
  //   setSelectedRowKeys(selectedKeys);
  // };

  const getSize = (size) => (!!size?.length ? size.join(', ') : '');

  const getPrintLocation = (positions) => positions.join(', ');

  const handleNavigateToDetail = (item) => {
    if (!item?.productTemplateId) return;

    const path = `${item.productTemplateId}`;
    navigate(path);
  };

  const handleClickEdit = (e, item) => {
    e.stopPropagation();
    console.log('edit', item);
    dispatch(setMaker(true));
  };

  const handleSettingItem = (e, item) => {
    e.stopPropagation();

    console.log({ item });
  };

  const onClick = (values, productTemplateId) => {
    values.domEvent.stopPropagation();
    console.log({ values }, values.productId);

    setDropdownOpen((prev) => ({
      ...prev,
      [productTemplateId]: false,
    }));
  };

  const handleDropdownOpenChange = (productTemplateId, open) => {
    console.log({ productTemplateId });
    setDropdownOpen((prev) => ({
      ...prev,
      [productTemplateId]: open,
    }));
  };

  return (
    <BaseLayout>
      <WrapperDashboardStyle $isTablet={isDesktop}>
        <PageTitle>Product templates</PageTitle>

        <HeaderTemplate>
          <TitleTemplate>Product templates</TitleTemplate>
          <BaseButton danger onClick={() => setOpenTemplate(true)}>
            <PlusIcon />
            Create product
          </BaseButton>
        </HeaderTemplate>

        <div style={{ padding: '20px' }}>
          <Row gutter={16} style={{ marginBottom: '16px', display: 'flex', alignItems: 'center' }}>
            {/* <Col>
              <SelectAllCheck onChange={onSelectAll}>Select All</SelectAllCheck>
            </Col> */}

            <Col flex="auto">
              <BaseInput
                placeholder="Search templates"
                onChange={(e) => setSearchText(e.target.value)}
                style={{ width: 300 }}
              />
            </Col>
          </Row>

          <List>
            <ListItem>
              <CreateProduct onClick={() => setOpenTemplate(true)}>
                <PlusIconBox>
                  <PlusIcon />
                </PlusIconBox>
                Create new product
              </CreateProduct>
            </ListItem>

            {filteredData?.map((item) => (
              <ListItem key={item.productTemplateId} onClick={() => handleNavigateToDetail(item)}>
                {/* <Checkbox checked={selectedRowKeys.includes(item.key)} onChange={() => onSelect(item)} /> */}
                <ImageWrapper $isDropdownOpen={dropdownOpen[item.productTemplateId]}>
                  <img src={item.image} alt={item.title} />
                  <ActionEditButton
                    onClick={(e) => handleClickEdit(e, item)}
                    $isDropdownOpen={dropdownOpen[item.productTemplateId]}
                  >
                    Edit
                  </ActionEditButton>

                  <Dropdown
                    menu={{ items, onClick: (values) => onClick(values, item.productTemplateId) }}
                    trigger={['click']}
                    onOpenChange={(open) => handleDropdownOpenChange(item.productTemplateId, open)}
                  >
                    <MoreOutlinedStyle
                      className="icon-dots"
                      onClick={(e) => handleSettingItem(e, item)}
                      $isDropdownOpen={dropdownOpen[item.productTemplateId]}
                    >
                      <MoreOutlined style={{ fontSize: '20px' }} />
                    </MoreOutlinedStyle>
                  </Dropdown>
                </ImageWrapper>

                <ListItemContent>
                  <ListItemTitle className="title">{item.title}</ListItemTitle>

                  <ListItemColor $customColor={item.color}></ListItemColor>

                  <p style={{ marginBottom: 0, flexShrink: 0 }}>{item?.printing || ''}</p>

                  <ListItemDescription>
                    <SizeStyle>{getSize(item.size)}</SizeStyle>
                    <p style={{ display: 'flex', height: '100%' }}>{getPrintLocation(item.printLocation)}</p>
                  </ListItemDescription>
                </ListItemContent>
              </ListItem>
            ))}
          </List>
        </div>

        {open && (
          <DesignMakerModal
            visible
            onClose={() => dispatch(setMaker(false))}
            onSaveTemplate={() => dispatch(setMaker(false))}
            defaultValues={dummyAreas}
          />
        )}

        <CreateProductTemplateModal visible={openTemplate} onClose={() => setOpenTemplate(false)} />
      </WrapperDashboardStyle>
    </BaseLayout>
  );
};

export const HeaderTemplate = styled.div`
  display: flex;
  justify-content: space-between;
  padding-bottom: 24px;
  border-bottom: 1px solid #e0e0e0;
`;

export const TitleTemplate = styled.h3`
  font-size: 36px;
  font-weight: 700;
`;

export const List = styled.div`
  display: grid;
  grid-column-gap: 32px;
  grid-row-gap: 40px;
  grid-template-columns: repeat(4, 1fr);

  @media (max-width: 1199.98px) {
    grid-template-columns: repeat(3, 1fr);
  }

  @media (max-width: 767.98px) {
    grid-template-columns: repeat(2, 1fr);
  }
`;

const ListItem = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  border: 1px solid #e8e8e8;
  border-radius: 16px;
  background: #fff;
  transition: all 0.2s linear;

  &:hover {
    cursor: pointer;
  }

  &:hover:not(:first-of-type) {
    background: #f5f5f5;
    border-color: #d9d9d9;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  }
`;

const ImageWrapper = styled.div`
  position: relative;
  margin: 0 auto;
  flex-shrink: 0;

  & img,
  & button {
    transition: all 0.3s linear;
  }

  &:hover {
    & button,
    & .icon-dots {
      display: inline-flex;
    }

    img {
      filter: brightness(0.6);
    }
  }

  & > img {
    width: 100%;
    aspect-ratio: 1;
    background-color: #f3f3f3;
    background-position: 50%;
    background-repeat: no-repeat;
    background-size: contain;
    border-top-left-radius: 16px;
    border-top-right-radius: 16px;

    filter: ${({ $isDropdownOpen }) => $isDropdownOpen && 'brightness(0.6)'};
  }
`;

const ListItemContent = styled.div`
  padding: 16px 8px;
  display: flex;
  flex: 1;
  flex-direction: column;
  gap: 8px;
`;

const ListItemTitle = styled.div`
  font-weight: bold;
  color: #333;
  flex-shrink: 0;

  -webkit-box-orient: vertical;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const ListItemDescription = styled.div`
  color: #555555;
  font-size: 14px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  height: 100%;

  & p {
    margin-bottom: 0;
  }
`;

const SizeStyle = styled.p`
  height: 100%;

  letter-spacing: 1.5px;
  word-spacing: 1.5px;
`;

const MoreOutlinedStyle = styled.div`
  position: absolute;
  display: none;
  top: 6px;
  right: 6px;
  width: 24px;
  min-width: 24px;
  height: 24px;
  background-color: #e5e5e5;
  align-items: center;
  justify-content: center;
  border-radius: 6px;
  transition: all 0.3s ease-in-out;

  display: ${({ $isDropdownOpen }) => $isDropdownOpen && 'inline-flex'};

  &:hover {
    background-color: var(--background-color);
  }
`;

const ActionEditButton = styled(BaseButton)`
  position: absolute;
  display: none;
  margin-right: 8px;
  height: 40px;
  width: 90%;
  left: 50%;
  top: 50%;
  background-color: #e5e5e5;
  transform: translate(-50%, -50%);
  transition: all 0.3s ease-in-out;

  display: ${({ $isDropdownOpen }) => $isDropdownOpen && 'inline-flex'};

  border-radius: 6px;
  box-shadow: unset;
  margin-right: 0;
`;

const CreateProduct = styled.div`
  min-height: 226px;
  padding: 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 16px;
  color: #333;
  margin: auto;
`;

const PlusIconBox = styled.div`
  width: 40px;
  height: 40px;
  font-weight: bold;
  border-radius: 50%;
  background-color: #0c8512;

  display: flex;
  align-items: center;
  justify-content: center;

  & > svg {
    color: #fff;
    height: 30px;
  }
`;

const SelectAllCheck = styled(Checkbox)`
  & .ant-checkbox-inner {
    border-radius: 0.1875rem;
    height: 1.25rem;
    width: 1.25rem;
    border: 1px solid var(--primary-color);
  }
`;

const Text = styled(BaseTypography.Text)`
  display: flex;
  align-items: center;
  /* justify-content: center; */
  height: 30px;
  font-size: 0.875rem;
  font-weight: 600;

  & > a {
    display: block;
  }

  @media only screen and ${media.md} {
    font-size: 1rem;
  }
`;

export default ProductTemplates;
