import { createSelector, createSlice } from '@reduxjs/toolkit';

const MakerSlice = createSlice({
  name: 'product',
  initialState: {
    open: false,
  },
  reducers: {
    setMaker: (state, action) => {
      state.open = action.payload;
    },
  },
  extraReducers: {},
});

const { actions, reducer } = MakerSlice;

export const { setMaker } = actions;

export default reducer;

export const makerData = (state) => state.maker;

export const makerDataSelector = createSelector([makerData], (state) => state);
