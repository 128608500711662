import { UserInfoModel, UpdateUserInfoRequest, ChangeUserPasswordRequest } from '@app/domain/UserModel';
import { httpApi } from './http.api';
import { notificationController } from '@app/controllers/notificationController';

export const getUser = async () => {
  try {
    const response = await httpApi.get('/api/v1/user/info');
    return response.data; // Assuming the API returns data in response.data
  } catch (error) {
    console.error('Error fetching user data:', error);
    throw error; // Re-throw the error to handle it upstream if needed
  }
};

export const updateUserInfo = (request: UpdateUserInfoRequest) => {
  const convertData = {
    ...request,
    fullname: request.fullName,
  };

  return httpApi.put<undefined | any>('/api/v1/user/info', { ...convertData }).then(({ data }) => {
    return data;
  });
};

export const changeUserPassword = (request: ChangeUserPasswordRequest) =>
  httpApi.put<undefined | any>('/api/v1/user/password', { ...request }).then(({ data }) => data);

export interface OverviewUser {
  orders: number;
  sales: number;
  customers: number;
  refunds: number;
  pending: number;
  confirmed: number;
  inTroduction: number;
  shipped: number;
  accepted: number;
  inTransit: number;
  delivered: number;
  cancel: number;
  productionTime: number;
  shippingTime: number;
  //
  balance: number;
  unsolvedTickets: number;
  tobePaidAmount: number;
}

export const getOverviewUser = () => httpApi.get<OverviewUser>('/api/v1/analytics/overview').then(({ data }) => data);

export type TCreateUserForm = {
  email: string;
  password: string;
  rePassword: string;
  fullname: string;
  role: string;
};

export const createUser = async (data: TCreateUserForm) => {
  try {
    const response = await httpApi.post('api/v1/user-manager/create', data);
    notificationController.success({ message: 'Created successfully!' });

    return response;
  } catch (error: any) {
    notificationController.error({ message: error.message });
  }
};

export type TFormData = {
  search?: string;
  status?: string[];
  createdTo?: Date | string;
  createdFrom?: Date | string;
  email?: string;
  pageSize: number;
  pageIndex: number;
};

export const getUserManager = async (data: TFormData) => {
  try {
    const response = await httpApi.post('api/v1/user-manager/search', data);

    return response;
  } catch (error: any) {
    notificationController.error({ message: error.message });
  }
};

export type StatusUser = {
  id: number | string;
  status: string;
};

export const changeStatusUser = async ({ id, status }: StatusUser) => {
  try {
    const response = await httpApi.put(`api/v1/user-manager/${id}/status`, { status });
    notificationController.success({ message: 'Updated successfully!' });

    return response;
  } catch (error: any) {
    notificationController.error({ message: error.message });
  }
};

export type PasswordUser = {
  id: number | string;
  formData: {
    password: string;
    rePassword: string;
  };
};

export const changePasswordUser = async ({ id, formData }: PasswordUser) => {
  try {
    const response = await httpApi.put(`api/v1/user-manager/${id}/password`, formData);

    notificationController.success({ message: 'Password updated successfully!' });
    return response;
  } catch (error: any) {
    notificationController.error({ message: error.message });
  }
};

export type BalanceUser = {
  id: number | string;
  formData: {
    amount: number;
    type: string;
    note?: string;
  };
};

export const changeBalanceUser = async ({ id, formData }: BalanceUser) => {
  try {
    const response = await httpApi.put(`api/v1/user-manager/${id}/wallet`, formData);
    notificationController.success({ message: 'Updated successfully!' });

    return response;
  } catch (error: any) {
    notificationController.error({ message: error.message });
  }
};

export const getUserDetail = async (id: string) => {
  try {
    const response = await httpApi.get(`api/v1/user-manager/${id}`);

    return response;
  } catch (error: any) {
    notificationController.error({ message: error.message });
  }
};
