import { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Pagination } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '@app/hooks/reduxHooks';
import { productDataSelector, getProductList } from '@app/store/slices/productSlice';

import { BaseSelect } from '@app/components/common/selects/BaseSelect/BaseSelect';
import { BaseButton } from '@app/components/common/BaseButton/BaseButton';
import BaseSelectFilter from '@app/components/common/BaseSelectFilter';
import { BaseInput } from '@app/components/common/inputs/BaseInput/BaseInput';
import { BaseModal } from '@app/components/common/BaseModal/BaseModal';
import { useResponsive } from '@app/hooks/useResponsive';

import { makerDataSelector, setMaker } from '@app/store/slices/makerSlice';

import { FONT_SIZE } from '@app/styles/themes/constants';
import { ListItemColor, ListColor } from '@app/pages/ProductTemplates/ProductTemplates.styles';
import { WrapperDashboardStyle } from '@app/pages/DashboardPages/DashboardPage.styles';

const initialQuery = {
  page: 0,
  size: 12,
  sort: 'createdDate,asc',
  isRecommend: undefined,
  status: undefined,
  s: undefined,
};

const StatusItems = [
  { label: 'Disable', value: 'DISABLE' },
  { label: 'Publish', value: 'PUBLISH' },
  { label: 'Draft', value: 'DRAFT' },
];

const BestSellerItems = [
  { label: 'True', value: 'true' },
  { label: 'False', value: 'false' },
];

const getSize = (size) => size.map((item) => item.size).join(', ');
const getPrice = (value) => value.map((item) => item.unitPrice)?.[0] ?? '';

const CreateProductTemplateModal = ({ visible, onClose }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { isDesktop } = useResponsive();

  const dispatch = useAppDispatch();
  const { open } = useAppSelector(makerDataSelector);

  const { product, loading } = useAppSelector(productDataSelector);

  const [valueSearch, setValueSearch] = useState('');

  const [searchIpt, setSearchIpt] = useState('');
  const [newStatus, setNewStatus] = useState('');

  const [productsSelected, setProductsSelected] = useState([]);
  const [isProductsSelected, setIsProductsSelected] = useState(false);
  const [selectedStatusItems, setSelectedStatusItems] = useState([]);
  const [selectedSellerItems, setSelectedSellerItems] = useState(undefined);

  const [currentPage, setCurrentPage] = useState(0);

  useEffect(() => {
    if (!visible) return;

    dispatch(
      getProductList({
        ...initialQuery,
        page: currentPage,
        status: selectedStatusItems,
        isRecommend: selectedSellerItems ?? undefined,
        s: valueSearch || undefined,
      }),
    );
  }, [dispatch, visible, currentPage, selectedStatusItems, selectedSellerItems, valueSearch]);

  useEffect(() => {
    if (visible) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }
    return () => {
      document.body.style.overflow = 'auto';
    };
  }, [visible]);

  const handleSearch = (value) => {
    if (value === '') {
      setValueSearch('');
    } else {
      setValueSearch(searchIpt);
      setCurrentPage(0);
    }
  };

  const handleChangeStatus = (selected) => {
    const query = selected;

    setSelectedStatusItems(query);
    setCurrentPage(0);
  };

  const handleChangeSeller = (selected) => {
    const query = selected;

    setSelectedSellerItems(query);
    setCurrentPage(0);
  };

  const handleResetFilter1 = () => {
    setSelectedStatusItems([]);
    setSelectedSellerItems(undefined);
    setValueSearch('');
    setSearchIpt('');
    setCurrentPage(0);
  };

  const handlePageChange = (page) => {
    console.log({ page });
    setCurrentPage(page - 1);
  };

  const onSelect = async (item) => {
    console.log({ item });

    await dispatch(setMaker(true));

    setTimeout(() => {
      onClose();
    }, 1000);
  };

  return (
    <BaseModalStyle
      open={visible}
      onCancel={onClose}
      footer={null}
      width="100%"
      centered
      title="Create a product template
"
    >
      <WrapperDashboardStyle $isTablet={isDesktop}>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            padding: '1.25rem',
            flexWrap: 'wrap',
          }}
        >
          <div style={{ display: 'flex', flexWrap: 'wrap', gap: '1rem' }}>
            <InputWrapper>
              <SearchIcon onClick={() => handleSearch()} />
              <InputStyle
                placeholder={t('header.search')}
                value={searchIpt}
                onChange={(event) => {
                  const value = event.target.value;
                  if (value === '') {
                    handleSearch('');
                  }
                  setSearchIpt(value);
                }}
                onKeyDown={(event) => {
                  if (event.key === 'Enter' && searchIpt) {
                    handleSearch();
                  }
                }}
              />
            </InputWrapper>
          </div>
        </div>

        <div style={{ padding: '1.25rem', paddingTop: 0, display: 'flex', alignItems: 'center', gap: '12px' }}>
          <div>Filter by:</div>
          <BaseSelectFilter
            width={!selectedStatusItems.length ? '100px' : selectedStatusItems.length > 1 ? '200px' : '140px'}
            options={StatusItems}
            selectedItems={selectedStatusItems}
            onChange={handleChangeStatus}
            placeholder="Status"
            dropdownMatchSelectWidth={false}
            defaultActiveFirstOption={false}
            bordered={!!selectedStatusItems?.length}
            allowClear
            autoClearSearchValue={false}
          />

          <BaseSelect
            width={120}
            height={36}
            bgColor={{ backgroundColor: !!selectedSellerItems ? 'white' : 'transparent' }}
            bgFilter={'transparent'}
            options={BestSellerItems}
            value={selectedSellerItems}
            onChange={handleChangeSeller}
            placeholder="Best Seller"
            optionFilterProp="children"
            dropdownMatchSelectWidth={false}
            defaultActiveFirstOption={false}
            bordered={!!selectedSellerItems}
            allowClear
            autoClearSearchValue={false}
          />

          {(!!selectedStatusItems?.length || !!selectedSellerItems || valueSearch) && (
            <BaseButton type="ghost" style={{ width: 110 }} onClick={handleResetFilter1}>
              Clear Filter
            </BaseButton>
          )}
        </div>

        {loading ? (
          <div style={{ textAlign: 'center', height: '600px' }}>Loading...</div>
        ) : (
          <>
            <div style={{ paddingBottom: '20px', paddingLeft: '20px' }}>{`Showing ${
              product?.totalElements ?? 0
            } results`}</div>

            <List>
              {product?.content?.map((item) => (
                <ListItem key={item.uuid} onClick={() => onSelect(item)}>
                  <ImageWrapper>
                    <img src={item.avatar} alt={item.name} />
                  </ImageWrapper>

                  <ListItemContent>
                    <ListItemTitle>{item.name}</ListItemTitle>

                    <ItemStyle>{item.productSku}</ItemStyle>

                    <ListColor>
                      {!!item.color?.length
                        ? item.color.map((color) => (
                            <ListItemColor key={color.hex} $customColor={color.hex}></ListItemColor>
                          ))
                        : 'N/A'}
                    </ListColor>

                    <ListItemDescription>
                      <CategoryNameStyle>{item.categoryName || ''}</CategoryNameStyle>

                      <SizeStyle>{getSize(item.unitPrices)}</SizeStyle>

                      <PriceStyle>${getPrice(item.unitPrices)}</PriceStyle>
                    </ListItemDescription>
                  </ListItemContent>
                </ListItem>
              ))}
            </List>
          </>
        )}

        <PaginationStyle
          current={currentPage + 1}
          pageSize={initialQuery.size}
          total={product?.totalElements ?? 0}
          onChange={handlePageChange}
          onShowSizeChange={(current, size) => {
            setCurrentPage(0);
            dispatch(getProductList({ ...initialQuery, page: 0, size }));
          }}
        />
      </WrapperDashboardStyle>
    </BaseModalStyle>
  );
};

export const BaseModalStyle = styled(BaseModal)`
  max-width: 100%;

  & .ant-modal-body {
    max-height: calc(100vh - 55px);
    overflow: auto;
  }

  & .ant-modal-content {
    border-radius: 0;
    height: 100vh;
  }
`;

export const InputWrapper = styled.div`
  position: relative;
  border-radius: 16px;
  width: 240px;
  box-shadow: 0px 3px 3px 0px rgba(0, 0, 0, 0.15);
  transition-duration: 0.3s;
`;

export const SearchIcon = styled(SearchOutlined)`
  font-size: 16px;
  position: absolute;
  z-index: 2;
  left: 9px;
  top: 54%;
  transform: translateY(-50%);
`;

export const InputStyle = styled(BaseInput)`
  background: #f5f5f5;
  border-radius: 16px;
  padding-left: 36px;
  color: var(--text-main-color);
  font-size: ${FONT_SIZE.md};
  height: 36px;

  &::placeholder {
    font-size: 13px;
    color: gray;
  }
`;

export const List = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 12px;

  @media (max-width: 1199.98px) {
    grid-template-columns: repeat(3, 1fr);
  }

  @media (max-width: 767.98px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (max-width: 549px) {
    grid-template-columns: repeat(1, 1fr);
  }
`;

const ListItem = styled.div`
  display: flex;
  flex-direction: column;
  border: 1px solid #e8e8e8;
  border-radius: 16px;
  background: #fff;
  cursor: pointer;
  transition: all 0.2s linear;

  &:hover {
    background: #f5f5f5;
    border-color: #d9d9d9;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  }
`;

const ImageWrapper = styled.div`
  max-width: 256px;
  height: 256px;
  margin: 0 auto;
  flex-shrink: 0;

  @media (max-width: 549px) {
    height: 100%;
  }

  & > img {
    width: 100%;
    aspect-ratio: 1;
    background-color: #f3f3f3;
    background-position: 50%;
    background-repeat: no-repeat;
    background-size: contain;
    border-top-left-radius: 16px;
    border-top-right-radius: 16px;
  }
`;

const ListItemContent = styled.div`
  padding: 16px 8px;
  display: flex;
  flex-direction: column;
  flex: 1;
  gap: 8px;
`;

const ListItemTitle = styled.div`
  font-weight: bold;
  color: #333;

  -webkit-box-orient: vertical;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  overflow: hidden;
  text-overflow: ellipsis;

  flex: 1;
`;

const ListItemDescription = styled.div`
  color: #555555;
  font-size: 14px;

  display: flex;
  flex-direction: column;
  gap: 8px;
  flex-shrink: 0;

  & p {
    margin-bottom: 0;
  }
`;

const ItemStyle = styled.p`
  margin-bottom: 0;
  margin-top: auto;
  flex-shrink: 0;
`;

const CategoryNameStyle = styled.p`
  margin-bottom: 0;
`;

const SizeStyle = styled.p`
  margin-bottom: 0;

  letter-spacing: 1.5px;
  word-spacing: 1.5px;
`;

const PriceStyle = styled.p`
  font-weight: bold;
  font-variant-numeric: proportional-nums;
  color: #333333;
`;

const PaginationStyle = styled(Pagination)`
  display: flex;
  justify-content: center;
  margin-top: 16px;

  & .ant-pagination-prev,
  .ant-pagination-next,
  .ant-pagination-jump-prev,
  .ant-pagination-jump-next,
  .ant-pagination-item,
  .ant-pagination-options {
    min-width: 2.0625rem;
    height: 2.0625rem;
    line-height: 2.0625rem;
    border-radius: 0;
    font-size: ${FONT_SIZE.xs};
  }
`;

export default CreateProductTemplateModal;
