import axios, { AxiosResponse } from 'axios';
import { AxiosError } from 'axios';
import {
  clearLocalStorageWhenLogout,
  deleteStorage,
  readRemember,
  readToken,
} from '@app/services/localStorage.service';
import { STORAGE_KEY } from '@app/constants/http';
import { redirectIfNotIn } from '@app/utils/utils';
import { refreshToken } from '@app/hooks/auth';

export const httpApi = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Methods': 'GET, POST, PUT, DELETE, OPTIONS',
    'Access-Control-Allow-Headers': 'Origin, X-Requested-With, Content-Type, Accept',
    Authorization: 'Basic',
  },
});

httpApi.interceptors.request.use(async (config) => {
  if (!config.headers) return config;

  if (config.baseURL === process.env.REACT_APP_BASE_URL) {
    const accessToken = readToken();

    if (accessToken) {
      config.headers['Authorization'] = `Bearer ${accessToken}`;
    } else {
      delete config.headers['Authorization'];
    }
  }

  return config;
});

httpApi.interceptors.response.use(
  (response: AxiosResponse) => response.data,
  async (error: AxiosError) => {
    // redirect to 404 to force reload page if network is offline
    if (error.code === 'ERR_NETWORK') {
      return Promise.reject(error.response?.data || error);
    }

    if (!error.response) return Promise.resolve();

    const originalRequest = error.config as typeof error.config & { _retry?: boolean };

    const status = error.response.status;

    const isRemember = readRemember();

    switch (status) {
      case 401: {
        if (isRemember && !originalRequest._retry) {
          originalRequest._retry = true;

          const errorMessage = error.response?.data?.message;
          deleteStorage(STORAGE_KEY.ACCESS_TOKEN);

          if (errorMessage === 'sc_unauthorized') {
            clearLocalStorageWhenLogout();
            window.location.href = `/auth/login?callBack=${window.location.pathname}`;
            return;
          }

          await refreshToken();
          const newAccessToken = readToken();

          originalRequest.headers = originalRequest.headers || {};
          originalRequest.headers['Authorization'] = `Bearer ${newAccessToken}`;
          return httpApi(originalRequest); // Retry request gốc
        } else {
          clearLocalStorageWhenLogout();
          window.location.href = `/auth/login?callBack=${window.location.pathname}`;
        }

        break;
      }
      case 403: {
        redirectIfNotIn('/403');
        break;
      }

      case 404: {
        redirectIfNotIn('/404');
        break;
      }
      case 500: {
        redirectIfNotIn('/500');
        break;
      }
      default:
        return Promise.reject(error.response?.data || error);
    }
  },
);

export interface ApiErrorData {
  message: string;
}

export interface Page<T> {
  content: T[];
  pageable: Pageable;
  last: boolean;
  totalElements: number;
  totalPages: number;
  size: number;
  number: number;
  sort: any[];
  first: boolean;
  numberOfElements: number;
  empty: boolean;
}

export interface Pageable {
  pageNumber: number;
  pageSize: number;
  sort: any[];
  offset: number;
  unpaged: boolean;
  paged: boolean;
}
