import styled from 'styled-components';

export const MainContainer = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 20px;
`;

export const TshirtContainer = styled.div`
  width: 400px;
  height: 400px;
  position: relative;
  background-color: #f5f5f5;
  border: 1px solid #ddd;
  border-radius: 24px;

  & .konvajs-content {
    margin: 0 auto;
  }
`;

export const Sidebar = styled.div`
  width: 300px;
  padding: 20px;
  background-color: #f0f2f5;
`;

export const RightToolsBar = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px;
  gap: 24px;
`;
