import { httpApi } from '@app/api/http.api';
import {
  clearLocalStorageWhenLogout,
  getRefreshToken,
  persistToken,
  setRefreshToken,
} from '@app/services/localStorage.service';

export const cancelRefreshToken = () => {
  clearLocalStorageWhenLogout();
  window.location.href = `/auth/login?callBack=${window.location.pathname}`;
};

let refreshTokenPromise: Promise<void> | null = null;

export async function refreshToken() {
  const refreshToken = getRefreshToken();

  if (!refreshToken) {
    cancelRefreshToken();
    return;
  }

  if (refreshTokenPromise) {
    return refreshTokenPromise;
  }

  refreshTokenPromise = (async () => {
    try {
      const response = await httpApi.get('/api/v1/auth/refresh-token');
      persistToken(response?.data?.accessToken || '');
      setRefreshToken(response?.data?.refreshToken || '');
      httpApi.defaults.headers.common['Authorization'] = response?.data?.accessToken || '';
    } catch (error) {
      cancelRefreshToken();
    } finally {
      refreshTokenPromise = null;
    }
  })();

  return refreshTokenPromise;
}
