import userReducer from '@app/store/slices/userSlice';
import authReducer from '@app/store/slices/authSlice';
import nightModeReducer from '@app/store/slices/nightModeSlice';
import themeReducer from '@app/store/slices/themeSlice';
import pwaReducer from '@app/store/slices/pwaSlice';
import historyReducer from '@app/store/slices/historySlice';
import myConfigsReducer from '@app/store/slices/myConfigsSlice';
import productReducer from '@app/store/slices/productSlice';
import makerReducer from '@app/store/slices/makerSlice';

export default {
  user: userReducer,
  auth: authReducer,
  nightMode: nightModeReducer,
  theme: themeReducer,
  pwa: pwaReducer,
  history: historyReducer,
  myConfigs: myConfigsReducer,
  product: productReducer,
  maker: makerReducer,
};
