import { HeaderTitle, ProductContainer } from '@app/components/catalog/conmon.styles';
import { BaseButton } from '@app/components/common/BaseButton/BaseButton';
import { BaseCol } from '@app/components/common/BaseCol/BaseCol';
import { BaseRow } from '@app/components/common/BaseRow/BaseRow';
import { BaseSpin } from '@app/components/common/BaseSpin/BaseSpin';
import { PageTitle } from '@app/components/common/PageTitle/PageTitle';
import { BaseForm } from '@app/components/common/forms/BaseForm/BaseForm';
import { BaseInput } from '@app/components/common/inputs/BaseInput/BaseInput';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  ColorType,
  ImageAreaType,
  UnitPriceType,
  dataType,
  getProductDetail,
  newProduct,
  positionsType,
  shippingRatesType,
} from '@app/api/product.api';
import { BaseSelect, Option } from '@app/components/common/selects/BaseSelect/BaseSelect';
import { getCategory } from '@app/api/category.api';
import { ColorPicker, PositionsTable, ShippingRatesTable, UnitPriceTable } from '@app/components/catalog/common';
import { notificationController } from '@app/controllers/notificationController';
import { BaseUpload } from '@app/components/common/BaseUpload/BaseUpload';
import { FONT_SIZE, FONT_WEIGHT } from '@app/styles/themes/constants';
import styled from 'styled-components';
import { CKEditorWrapper } from '@app/components/common/BaseCKEditor/BaseCKEditor';
import { httpApi } from '@app/api/http.api';
import { BaseCard as CommonCard } from '@app/components/common/BaseCard/BaseCard';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { ReactComponent as ChevronRightIcon } from '@app/assets/icons/chevron-right.svg';
import { BaseCheckbox } from '@app/components/common/BaseCheckbox/BaseCheckbox';
import DOMPurify from 'dompurify';
import { Form } from 'antd';
import BaseLayout from '@app/components/layouts/BaseLayout';
import ImagesAndPrintingArea from '@app/components/ImagesAndPrintingArea';

const initProduct: dataType = {
  categoryUuid: '',
  productSku: '',
  quantity: '',
  name: '',
  status: '',
  avatar: [],
  images: [],
  colors: [],
  unitPrices: [],
  descriptions: '',
  positions: [],
  shippingRates: [],
  recommend: true,
  areas: [],
};
type categoryType = {
  name: string;
  preCode: string;
  uuid: string;
};
const DraggerTitle = styled.div`
  font-size: ${FONT_SIZE.md};
  font-weight: ${FONT_WEIGHT.bold};
`;

const NewProductPage: React.FC = () => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [category, setCategory] = useState<categoryType[]>([]);
  const [shippingRates, setShippingRates] = useState<shippingRatesType[]>([]);
  const [positions, setPositions] = useState<positionsType[]>([]);
  const [colors, setColors] = useState<ColorType[]>([]);
  const [unitPrices, setUnitPrices] = useState<UnitPriceType[]>([]);
  const [productSku, setProductSku] = useState<string>('');
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const [dataProduct, setDatProduct] = useState<dataType>(initProduct);
  const [isDataLoaded, setIsDataLoaded] = useState(false);

  const getData = useCallback(async () => {
    try {
      setLoading(true);
      const params = {
        sort: 'createdDate,desc',
        page: 0,
        size: 1000,
      };
      const [resCate, resProduct] = await Promise.all([
        getCategory(params),
        getProductDetail(searchParams.get('cloneProduct') || ''),
      ]);
      setLoading(false);
      const category = resCate.content;
      setCategory(category);
      setColors(resProduct.colors);
      setShippingRates(resProduct.shippingRates);
      setPositions(resProduct.positions);
      setUnitPrices(resProduct.unitPrices);
      const getProductSku = (uuid: string) => {
        const cate = category.find((item: categoryType) => item.uuid === uuid);
        return cate ? cate.preCode : '';
      };
      const productSku = getProductSku(resProduct.categoryUuid);
      const productSkuValue = resProduct.productSku.replace(productSku, '');
      setProductSku(productSku);
      setDatProduct((dataProduct) => ({
        ...dataProduct,
        ...resProduct,
        productSku: productSkuValue,
        images: [],
      }));
      setIsDataLoaded(true);
    } catch (err) {
      navigate('/404');
    }
  }, [navigate]);

  useEffect(() => {
    const uuid: string = searchParams.get('cloneProduct') || '';
    if (uuid != '') {
      getData();
    }
  }, [getData]);

  useEffect(() => {
    const params = {
      sort: 'createdDate,desc',
      page: 0,
      size: 1000,
    };
    getCategory(params).then((res) => {
      setCategory(res.content);
    });
  }, []);

  const handleUploadImg = async (listImages: any) => {
    if (!listImages || (Array.isArray(listImages) && listImages.length === 0)) {
      return null;
    } else if (typeof listImages === 'object' && Object.keys(listImages).length === 0) {
      return null;
    } else if (!listImages) {
      return null;
    } else if (typeof listImages === 'string' || listImages instanceof String) {
      return [listImages];
    } else {
      const uploadPromises = listImages?.map(async (fileInfo: { originFileObj: File }) => {
        if (typeof fileInfo.originFileObj === 'string') {
          return fileInfo.originFileObj;
        }
        const formData = new FormData();
        formData.append('file', fileInfo.originFileObj);
        try {
          const res = await httpApi.post(`/api/v1/upload/file`, formData);
          return res.data;
        } catch (error) {
          throw new Error(`Upload ${fileInfo.originFileObj.name} error!`);
        }
      });
      return await Promise.all(uploadPromises);
    }
  };

  // const handleUploadMockup = async (positions: positionsType[]) => {
  //   const uploadPromises = positions.map(async (data: positionsType) => {
  //     const response = await handleUploadImg(data.mockup);
  //     const newData = { ...data, mockup: '' };
  //     return newData;
  //   });
  //   return await Promise.all(uploadPromises);
  // };

  const handleUploadAreas = async (areas: ImageAreaType[]) => {
    const uploadPromises = areas?.map(async (data: ImageAreaType) => {
      const response = await handleUploadImg(data.file);
      const newData = { area: data.area, position: data.position, src: response ? response[0] : '' };
      return newData;
    });

    return await Promise.all(uploadPromises);
  };

  const handleSubmit = async (values: dataType) => {
    setLoading(true);
    try {
      const data: dataType = {
        name: values.name,
        categoryUuid: values.categoryUuid,
        unitPrices: unitPrices,
        productSku: values.productSku,
        quantity: values.quantity,
        status: values.status,
        colors: colors,
        descriptions: DOMPurify.sanitize(values.descriptions),
        shippingRates: shippingRates,
        positions: positions,
        avatar: values.avatar,
        images: [],
        recommend: values.recommend,
        areas: [],
      };

      console.log({ data });

      const [avatarUrls, areasResult, imagesUrls] = await Promise.all([
        handleUploadImg(values?.avatar),
        handleUploadAreas(values?.areas),
        handleUploadImg(values?.images),
      ]);

      console.log('checkkk: ', { avatarUrls, areasResult, imagesUrls });

      if (avatarUrls) {
        data.avatar = avatarUrls[0] as string;
      }

      // if (positionsResult) {
      //   data.positions = positionsResult as positionsType[];
      // }
      if (areasResult) {
        data.areas = areasResult as ImageAreaType[];
      }

      if (imagesUrls) {
        data.images = imagesUrls as string[];
      }

      console.log('last: ', data);

      await newProduct(data);

      form.resetFields();

      setColors([]);
      setShippingRates([]);
      setPositions([]);
      setUnitPrices([]);
      notificationController.success({ message: 'Create new product success' });
      navigate('/catalog/product');
    } catch (err) {
      if (err instanceof Error) {
        notificationController.error({ message: err.message });
      } else {
        notificationController.error({ message: 'Create product fail' });
      }
    } finally {
      setLoading(false);
    }
  };
  const handleShippingRatesChange = useCallback((newData: shippingRatesType[]) => {
    setShippingRates(newData);
  }, []);
  const handleUnitPricesChange = useCallback((newData: UnitPriceType[]) => {
    setUnitPrices(newData);
  }, []);
  const handlePositionsChange = useCallback((newData: positionsType[]) => {
    if (newData.length === 0) {
      form.setFieldsValue({ areas: [] });
    }
    setPositions(newData);
  }, []);
  const handleSelectChange = () => {
    const uuid = form.getFieldValue('categoryUuid');
    const temp = category.find((item) => item.uuid === uuid);
    if (temp) {
      setProductSku(temp.preCode);
    } else {
      setProductSku('');
    }
  };
  const uploadProps = {
    name: 'file',
    height: 100,
    accept: 'image/*',
    beforeUpload: () => {
      return false;
    },
  };

  const getFileListFromEvent = (e: any) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e && e.fileList;
  };

  const handleChangePrintArea = (data: any) => {
    // console.log({ data });
  };
  return (
    <BaseLayout>
      <>
        <PageTitle>{t('product.new')}</PageTitle>
        <ProductContainer>
          <BaseSpin spinning={loading}>
            <CommonCard
              title={
                <HeaderTitle>
                  <Link className="cursor" to={'/catalog/product'}>
                    {t('common.product')}
                  </Link>
                  <span>
                    <ChevronRightIcon />
                  </span>
                  <h4 className="subject">{t('product.new')}</h4>
                </HeaderTitle>
              }
            >
              <div className="product-main">
                <BaseForm
                  form={form}
                  id="product-form"
                  layout="vertical"
                  onFinish={handleSubmit}
                  initialValues={dataProduct}
                >
                  <BaseRow gutter={[20, 24]}>
                    <BaseCol span={16}>
                      <BaseForm.Item
                        name="name"
                        label={'Name'}
                        rules={[{ required: true, message: 'Name is required' }]}
                      >
                        <BaseInput />
                      </BaseForm.Item>

                      <BaseForm.Item name={'descriptions'} label={'Descriptions'}>
                        <CKEditorWrapper />
                      </BaseForm.Item>

                      <BaseForm.Item name="shippingRates" label={'Shipping Rates'}>
                        <ShippingRatesTable initData={dataProduct.shippingRates} getData={handleShippingRatesChange} />
                      </BaseForm.Item>

                      <BaseForm.Item name="positions" label={'Extra Fees'}>
                        <PositionsTable initData={dataProduct.positions} getData={handlePositionsChange} />
                      </BaseForm.Item>
                    </BaseCol>

                    <BaseCol span={8}>
                      <BaseRow>
                        <BaseCol lg={12} span={24} style={{ paddingRight: '0.25rem' }}>
                          <BaseForm.Item
                            name="categoryUuid"
                            label={'Category'}
                            rules={[{ required: true, message: 'Category is required' }]}
                          >
                            <BaseSelect height={36} width={120} onChange={handleSelectChange}>
                              <Option value="" hidden>
                                {t('common.category')}
                              </Option>
                              {category.map((item: categoryType) => (
                                <Option key={item.uuid} value={item.uuid}>
                                  {item.name}
                                </Option>
                              ))}
                            </BaseSelect>
                          </BaseForm.Item>
                        </BaseCol>
                        <BaseCol lg={12} span={24} style={{ paddingLeft: '0.25rem' }}>
                          <BaseForm.Item
                            name={'productSku'}
                            label={'Product Sku'}
                            rules={[{ required: true, message: 'Product Sku is required' }]}
                          >
                            <BaseInput addonBefore={<>{productSku}</>} />
                          </BaseForm.Item>
                        </BaseCol>
                      </BaseRow>

                      <BaseRow>
                        <BaseCol lg={12} span={24} style={{ paddingRight: '0.25rem' }}>
                          <BaseForm.Item
                            name="quantity"
                            label={'Quantity'}
                            rules={[
                              { required: true, message: 'Quantity is required' },
                              () => ({
                                validator(_, value) {
                                  if (!(value < '0') || value == '') {
                                    return Promise.resolve();
                                  }
                                  return Promise.reject('The value must be a positive number');
                                },
                              }),
                            ]}
                          >
                            <BaseInput type="number" placeholder="Quantity" />
                          </BaseForm.Item>
                        </BaseCol>
                        <BaseCol lg={12} span={24} style={{ paddingLeft: '0.25rem' }}>
                          <BaseForm.Item
                            name="status"
                            label={'Status'}
                            rules={[{ required: true, message: 'Status is required' }]}
                          >
                            <BaseSelect height={36} width={'100%'}>
                              <Option value="" hidden>
                                Status
                              </Option>
                              <Option value="DRAFT">Draft</Option>
                              <Option value="PUBLISH">Publish</Option>
                              <Option value="DISABLE">Disable</Option>
                            </BaseSelect>
                          </BaseForm.Item>
                        </BaseCol>
                      </BaseRow>

                      <BaseForm.Item
                        name="avatar"
                        label={'Avatar'}
                        rules={[{ required: true, message: 'Avatar is required' }]}
                        valuePropName="fileList"
                        getValueFromEvent={getFileListFromEvent}
                      >
                        <BaseUpload.Dragger multiple={false} {...uploadProps} maxCount={1}>
                          <DraggerTitle>{t('uploads.dragUpload')}</DraggerTitle>
                        </BaseUpload.Dragger>
                      </BaseForm.Item>

                      <BaseForm.Item name="unitPrices" label={'Unit Prices'}>
                        <UnitPriceTable
                          initData={dataProduct.unitPrices.sort((a: UnitPriceType, b: UnitPriceType) => {
                            const ORDER = ['OS', 'XS', 'S', 'M', 'L', 'XL', '2XL', '3XL', '4XL', '5XL'];
                            return ORDER.indexOf(a.size) - ORDER.indexOf(b.size);
                          })}
                          getData={handleUnitPricesChange}
                        />
                      </BaseForm.Item>

                      <BaseForm.Item valuePropName="checked" name="recommend" label={'Recommend'}>
                        <BaseCheckbox>Is recommend</BaseCheckbox>
                      </BaseForm.Item>

                      <BaseForm.Item
                        name="images"
                        label={'Images'}
                        valuePropName="fileList"
                        getValueFromEvent={getFileListFromEvent}
                      >
                        <BaseUpload.Dragger {...uploadProps} multiple={true}>
                          <DraggerTitle>{t('uploads.dragUpload')}</DraggerTitle>
                        </BaseUpload.Dragger>
                      </BaseForm.Item>

                      <BaseForm.Item name="colors" label={'Colors'}>
                        <ColorPicker values={colors} onChangeColor={(values: ColorType[]) => setColors(values)} />
                      </BaseForm.Item>
                    </BaseCol>
                  </BaseRow>

                  {!!positions?.length && (
                    <BaseRow gutter={[20, 24]}>
                      <BaseCol span={24}>
                        <BaseForm.Item name="areas" label={'Images & Printing Areas'}>
                          <ImagesAndPrintingArea onChange={handleChangePrintArea} positions={positions} />
                        </BaseForm.Item>
                      </BaseCol>
                    </BaseRow>
                  )}
                </BaseForm>

                <div className="product-footer">
                  <BaseButton type="primary" htmlType="submit" form={'product-form'}>
                    OK
                  </BaseButton>
                </div>
              </div>
            </CommonCard>
          </BaseSpin>
        </ProductContainer>
      </>
    </BaseLayout>
  );
};

export default NewProductPage;
