import React, { useState, useRef, useEffect, useMemo } from 'react';
import { Tabs, Button, Upload, Select, Modal } from 'antd';
import { UploadOutlined, ScissorOutlined } from '@ant-design/icons';
import { Stage, Layer, Image as KonvaImage, Rect, Text, Circle, Transformer, Line, Group } from 'react-konva';
import Konva from 'konva';
import useImage from 'use-image';
import tshirtRedBack from '@app/assets/images/back.png';
import tshirtRedFront from '@app/assets/images/tshirt-red.png';
import tshirtRedLeft from '@app/assets/images/left-sleeve1.png';
import tshirtRedRight from '@app/assets/images/right-sleeve1.png';

import tshirtBlueBack from '@app/assets/images/blue-back.png';
import tshirtBlueFront from '@app/assets/images/blue-front.png';
import tshirtBlueLeft from '@app/assets/images/blue-left-sleeve.png';
import tshirtBlueRight from '@app/assets/images/blue-right-sleeve.png';

import BaseLayout from '@app/components/layouts/BaseLayout';
import Cropper from 'react-easy-crop';
import { MainContainer, RightToolsBar, Sidebar, TshirtContainer } from './DesignMaker.styles';
import DesignMakerModal from './DesignMakerModal';
import { PageTitle } from '@app/components/common/PageTitle/PageTitle';
// import ContentDesign from './ContentDesign';

const { Option } = Select;

type UploadDesign = {
  [key in TabName]: {
    image: string | null;
    props: {
      x: number;
      y: number;
      width: number;
      height: number;
      rotation: number;
      scaleX: number;
      scaleY: number;
      isDragging: boolean;
    };
  };
};

type TabName = 'front' | 'back' | 'left-sleeve' | 'right-sleeve';

export interface DesignState {
  image: string;
  baseImageSrc: string;
  x: number;
  y: number;
  width: number;
  height: number;
  rotation: number;
  scaleX: number;
  scaleY: number;
}

export interface Designs {
  [key: string]: DesignState;
  front: DesignState;
  back: DesignState;
  'left-sleeve': DesignState;
  'right-sleeve': DesignState;
}

// Dummy API simulation
const fetchDesignData = async (tab: TabName): Promise<{ image: string | null }> => {
  // Simulated API response
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve({ image: null }); // Default empty state
    }, 500);
  });
};

const DesignMakerPage = () => {
  const [currentTab, setCurrentTab] = useState<TabName>('front');
  const [uploadedDesign, setUploadedDesign] = useState<string | null>(null);
  const [uploadedDesign1, setUploadedDesign1] = useState({
    front: '',
    back: '',
  });

  ///////////

  ///////////

  const [tshirtColor, setTshirtColor] = useState('#ffffff');
  const [imageDesign, setImageDesign] = useState<string>(tshirtRedFront);

  const [image] = useImage(imageDesign);
  const stageRef = useRef(null);

  const [cropModalVisible, setCropModalVisible] = useState(false);
  const [cropSettings, setCropSettings] = useState({ crop: { x: 0, y: 0 }, zoom: 1, aspect: 1 });

  const [selectedImage, setSelectedImage] = useState(false);

  const [highlightEdges, setHighlightEdges] = useState({
    top: false,
    right: false,
    bottom: false,
    left: false,
    center: false,
    centerColumn: false,
    centerRow: false,
  });

  const stageWidth = 400;
  const stageHeight = 400;
  const areaWidth = 150;
  const areaHeight = 200;
  const imageWidth = 30;
  const imageHeight = 30;

  const areaCenter = { x: stageWidth / 2, y: stageHeight / 2 };
  const snapThreshold = 5;

  const initialImagePosition = {
    image: '',
    baseImageSrc: '',
    x: areaCenter.x - imageWidth / 2,
    y: areaCenter.y - imageHeight / 2,
    width: imageWidth,
    height: imageHeight,
    rotation: 0,
    isDragging: false,
    scaleX: 1,
    scaleY: 1,
  };

  const [designs, setDesigns] = useState<Designs>({
    front: initialImagePosition,
    back: initialImagePosition,
    'left-sleeve': initialImagePosition,
    'right-sleeve': initialImagePosition,
  });

  const [uploadedImage] = useImage(designs[currentTab].image || '');

  const shapeRef = useRef<Konva.Image | null>(null);
  const trRef = useRef<Konva.Transformer | null>(null);
  const deleteGroupRef = useRef<Konva.Group>(null);

  const [isOuterAreaActive, setIsOuterAreaActive] = useState(false); // Area to

  const [imageProps, setImageProps] = useState(initialImagePosition);

  console.log({ uploadedImage, designs });

  const initialDeleteIconPosition = {
    x: initialImagePosition.x + initialImagePosition.width,
    y: initialImagePosition.y,
  };

  const [deleteIconPosition, setDeleteIconPosition] = useState(initialDeleteIconPosition);

  const areaProps = {
    x: areaCenter.x - areaWidth / 2,
    y: areaCenter.y - areaHeight / 2,
    width: areaWidth,
    height: areaHeight,
  };

  const [opacity, setOpacity] = useState<number>(1);

  useEffect(() => {
    if (selectedImage && trRef.current && shapeRef.current) {
      trRef.current.nodes([shapeRef.current]);
      trRef.current.getLayer()?.batchDraw();
    }
  }, [selectedImage]);

  const updateDeleteIconPosition = () => {
    let animationFrameId: number | null = null;

    if (animationFrameId !== null) {
      cancelAnimationFrame(animationFrameId);
    }

    animationFrameId = requestAnimationFrame(() => {
      const transformer = trRef.current;
      const deleteGroup = deleteGroupRef.current;

      if (!transformer || !deleteGroup) return;

      const topRightAnchor = transformer.findOne('.top-right');

      if (topRightAnchor) {
        const anchorPosition = topRightAnchor.absolutePosition();

        deleteGroup.absolutePosition({
          x: anchorPosition.x,
          y: anchorPosition.y,
        });

        setDeleteIconPosition({
          x: anchorPosition.x,
          y: anchorPosition.y,
        });
      }

      animationFrameId = null;
    });
  };

  // Function to handle clicking and holding the mouse on the image
  const handleMouseDown = (evt: Konva.KonvaEventObject<MouseEvent>) => {
    evt.cancelBubble = true;

    setImageProps((prev) => ({
      ...prev,
      isDragging: true,
    }));

    setIsOuterAreaActive(true);
    setSelectedImage(true);
  };

  // Function to handle mouse release on image
  const handleMouseUp = (e: Konva.KonvaEventObject<MouseEvent>) => {
    e.cancelBubble = true;
    setIsOuterAreaActive(false);
  };

  const [modalVisible, setModalVisible] = useState(false);

  const showModal = () => {
    if (!uploadedImage) return;

    setModalVisible(true);
    setSelectedImage(false);
  };

  const handleOk = () => {
    setModalVisible(false);
  };

  const handleCancel = () => {
    setModalVisible(false);
  };

  // Fetch initial data
  // useEffect(() => {
  //   const loadInitialData = async () => {
  //     const data = await fetchDesignData(currentTab);
  //     setDesigns((prev) => ({
  //       ...prev,
  //       [currentTab]: { ...prev[currentTab], image: data.image },
  //     }));
  //   };
  //   loadInitialData();
  // }, [currentTab]);

  const handleTabChange = (key: string) => {
    setCurrentTab(key as TabName);
    // switch (key) {
    //   case 'front':
    //     // setImageDesign(tshirtRedFront);

    //     break;
    //   case 'back':
    //     // setImageDesign(tshirtRedBack);

    //     break;
    //   default:
    //   // setImageDesign(tshirtRedFront);
    // }
  };

  const updateDesign = (newDesign: Partial<DesignState>) => {
    console.log({ newDesign });
    setDesigns((prev) => ({
      ...prev,
      [currentTab]: { ...prev[currentTab], ...newDesign },
    }));
  };

  const handleUpload = ({ file }: { file: File }) => {
    const reader = new FileReader();

    reader.onload = () => {
      setUploadedDesign(reader.result as string);

      console.log({ reader });

      setDesigns((prev) => ({
        ...prev,
        [currentTab]: { ...prev[currentTab], image: reader.result as string },
      }));
      // setUploadedDesign1((prev) => ({
      //   ...prev,
      //   [currentTab]: reader.result as string,
      // }));

      setSelectedImage(false);
    };
    reader.readAsDataURL(file);
  };

  const handleDragEnd = (e: Konva.KonvaEventObject<DragEvent>) => {
    updateDeleteIconPosition();
    setOpacity(1);

    const node = e.target;

    setImageProps((prev) => ({
      ...prev,
      x: node.x(),
      y: node.y(),
    }));
  };

  const handleDragMove = (e: Konva.KonvaEventObject<DragEvent>) => {
    e.cancelBubble = true;
    updateDeleteIconPosition();
    setOpacity(0.5);

    const node = e.target;
    const boundingBox = node.getClientRect();

    const rotation = node.rotation();
    const angleRad = (rotation * Math.PI) / 180;

    const width = node.width() * node.scaleX();
    const height = node.height() * node.scaleY();

    const imageCenter = {
      x: node.x() + (width / 2) * Math.cos(angleRad) - (height / 2) * Math.sin(angleRad),
      y: node.y() + (width / 2) * Math.sin(angleRad) + (height / 2) * Math.cos(angleRad),
    };

    const areaCenter = {
      x: areaProps.x + areaProps.width / 2,
      y: areaProps.y + areaProps.height / 2,
    };

    const minDistanceToTop = Math.abs(boundingBox.y - areaProps.y);
    const minDistanceToRight = Math.abs(boundingBox.x + boundingBox.width - (areaProps.x + areaProps.width));
    const minDistanceToBottom = Math.abs(boundingBox.y + boundingBox.height - (areaProps.y + areaProps.height));
    const minDistanceToLeft = Math.abs(boundingBox.x - areaProps.x);

    const snapToTop = minDistanceToTop < snapThreshold;
    const snapToRight = minDistanceToRight < snapThreshold;
    const snapToBottom = minDistanceToBottom < snapThreshold;
    const snapToLeft = minDistanceToLeft < snapThreshold;

    const isNearCenter =
      Math.abs(imageCenter.x - areaCenter.x) < snapThreshold && Math.abs(imageCenter.y - areaCenter.y) < snapThreshold;
    const isNearCenterColumn = Math.abs(imageCenter.x - areaCenter.x) < snapThreshold;
    const isNearCenterRow = Math.abs(imageCenter.y - areaCenter.y) < snapThreshold;

    // Update state
    setHighlightEdges({
      top: snapToTop,
      right: snapToRight,
      bottom: snapToBottom,
      left: snapToLeft,
      center: isNearCenter,
      centerColumn: isNearCenterColumn,
      centerRow: isNearCenterRow,
    });

    // Snapping logic
    let newX = node.x();
    let newY = node.y();

    if (isNearCenter) {
      // Snap to center
      const rotatedOffsetX = (width / 2) * Math.cos(angleRad) - (height / 2) * Math.sin(angleRad);
      const rotatedOffsetY = (width / 2) * Math.sin(angleRad) + (height / 2) * Math.cos(angleRad);

      newX = areaCenter.x - rotatedOffsetX;
      newY = areaCenter.y - rotatedOffsetY;
    } else {
      // Snapping vào trục giữa và các cạnh/góc
      if (isNearCenterColumn) {
        const rotatedOffsetX = (width / 2) * Math.cos(angleRad) - (height / 2) * Math.sin(angleRad);
        newX = areaCenter.x - rotatedOffsetX;
      }
      if (isNearCenterRow) {
        const rotatedOffsetY = (width / 2) * Math.sin(angleRad) + (height / 2) * Math.cos(angleRad);
        newY = areaCenter.y - rotatedOffsetY;
      }

      // Snapping vào các cạnh và góc
      if (snapToTop) {
        newY = areaProps.y - (boundingBox.y - node.y());
        if (snapToLeft) {
          newX = areaProps.x - (boundingBox.x - node.x()); // Top-left
        } else if (snapToRight) {
          newX = areaProps.x + areaProps.width - boundingBox.width - (boundingBox.x - node.x()); // Top-right
        }
      } else if (snapToBottom) {
        newY = areaProps.y + areaProps.height - boundingBox.height - (boundingBox.y - node.y());
        if (snapToLeft) {
          newX = areaProps.x - (boundingBox.x - node.x()); // Bottom-left
        } else if (snapToRight) {
          newX = areaProps.x + areaProps.width - boundingBox.width - (boundingBox.x - node.x()); // Bottom-right
        }
      } else if (snapToLeft) {
        newX = areaProps.x - (boundingBox.x - node.x());
      } else if (snapToRight) {
        newX = areaProps.x + areaProps.width - boundingBox.width - (boundingBox.x - node.x());
      }
    }

    node.position({ x: newX, y: newY });

    const isFullyOutOfArea =
      boundingBox.x + boundingBox.width < areaProps.x ||
      boundingBox.x > areaProps.x + areaProps.width ||
      boundingBox.y + boundingBox.height < areaProps.y ||
      boundingBox.y > areaProps.y + areaProps.height;

    if (isFullyOutOfArea) {
      const rotatedOffsetX = (width / 2) * Math.cos(angleRad) - (height / 2) * Math.sin(angleRad);
      const rotatedOffsetY = (width / 2) * Math.sin(angleRad) + (height / 2) * Math.cos(angleRad);

      setIsOuterAreaActive(false);
      updateDeleteIconPosition();

      // Synchronize location with node
      node.position({
        x: areaCenter.x - rotatedOffsetX,
        y: areaCenter.y - rotatedOffsetY,
      });
      node.stopDrag();
    }
  };

  const handleDragStart = (e: Konva.KonvaEventObject<DragEvent>) => {
    updateDeleteIconPosition();
    e.cancelBubble = true;

    setImageProps((prev) => ({
      ...prev,
      isDragging: true,
    }));
  };

  const handleTransformEnd = () => {
    const node = shapeRef.current;
    if (!node) return;

    const scaleX = node.scaleX();
    const scaleY = node.scaleY();

    // we will reset it back
    node.scaleX(1);
    node.scaleY(1);

    setImageProps((prev) => ({
      ...prev,
      x: node.x(),
      y: node.y(),
      rotation: node.rotation(),
      width: Math.max(5, node.width() * scaleX),
      height: Math.max(node.height() * scaleY),
    }));
  };

  const handleApplyCrop = () => {
    setUploadedDesign(uploadedDesign);
    setCropModalVisible(false);
  };

  const handleColorChange = (colorCode: string) => {
    console.log({ colorCode });
    // const selectedColor = dummyColors.find((color) => color.code === colorCode);
    // if (selectedColor) {
    //   setTshirtColor(selectedColor.image);
    // }
  };

  const checkDeselect = (e: Konva.KonvaEventObject<MouseEvent | TouchEvent>) => {
    const isTransformer = trRef.current?.getChildren()?.some((child) => child === e.target);
    const isImage =
      e.target.attrs.name === 'interaction-image' || e.target.attrs?.text === '⨯' || e.target.attrs?.fill === '#f14931';

    if (!isImage && !isTransformer) {
      setSelectedImage(false);
    }
  };

  const handleImageMouseEnter = (evt: Konva.KonvaEventObject<MouseEvent>) => {
    const stage = evt.target.getStage();

    if (stage) {
      stage.container().style.cursor = 'move';
    }
  };

  const handleDeleteMouseEnter = (evt: Konva.KonvaEventObject<MouseEvent>) => {
    const stage = evt.target.getStage();

    if (stage) {
      stage.container().style.cursor = 'pointer';
    }
  };

  const handleResetCursor = (evt: Konva.KonvaEventObject<MouseEvent>) => {
    const stage = evt.target.getStage();

    if (stage) {
      stage.container().style.cursor = 'default';
    }
  };

  // Array of tab items
  // const tabItems = [
  //   {
  //     key: 'front',
  //     label: 'Front',
  //     children: (
  //       <TshirtContainer style={{ backgroundColor: tshirtColor }}>
  //         <Stage width={stageWidth} height={stageHeight} onMouseDown={checkDeselect} onTouchStart={checkDeselect}>
  //           <Layer>
  //             <KonvaImage image={image} width={400} height={400} />

  //             {uploadedImage && (
  //               <>
  //                 <Group
  //                   clip={{
  //                     x: areaProps.x,
  //                     y: areaProps.y,
  //                     width: areaProps.width,
  //                     height: areaProps.height,
  //                   }}
  //                 >
  //                   <KonvaImage
  //                     ref={shapeRef}
  //                     image={uploadedImage}
  //                     {...imageProps}
  //                     name="interaction-image"
  //                     draggable
  //                     onMouseDown={handleMouseDown}
  //                     onMouseUp={handleMouseUp}
  //                     onDragEnd={handleDragEnd}
  //                     onDragStart={handleDragStart}
  //                     onDragMove={handleDragMove}
  //                     onTransform={(e: Konva.KonvaEventObject<Event>) => {
  //                       e.cancelBubble = true;

  //                       updateDeleteIconPosition();
  //                       setSelectedImage(true);
  //                       setIsOuterAreaActive(true);
  //                     }}
  //                     onTransformEnd={handleTransformEnd}
  //                     onMouseEnter={handleImageMouseEnter}
  //                     onMouseLeave={handleResetCursor}
  //                   />
  //                 </Group>
  //                 {isOuterAreaActive && (
  //                   <Group>
  //                     <Line
  //                       points={[areaCenter.x, areaProps.y, areaCenter.x, areaProps.y + areaProps.height]}
  //                       stroke={'#4AFFFF'}
  //                       strokeWidth={1}
  //                       dash={!highlightEdges.centerColumn ? [2, 2] : undefined}
  //                       listening={false}
  //                     />

  //                     <Line
  //                       points={[areaProps.x, areaCenter.y, areaProps.x + areaProps.width, areaCenter.y]}
  //                       stroke={'#4AFFFF'}
  //                       strokeWidth={1}
  //                       dash={!highlightEdges.centerRow ? [2, 2] : undefined}
  //                       listening={false}
  //                     />

  //                     <Line
  //                       points={[areaProps.x, areaProps.y, areaProps.x + areaProps.width, areaProps.y]}
  //                       stroke="#4AFFFF"
  //                       strokeWidth={1}
  //                       dash={!highlightEdges.top ? [2, 2] : undefined}
  //                       listening={false}
  //                     />

  //                     <Line
  //                       points={[
  //                         areaProps.x + areaProps.width,
  //                         areaProps.y,
  //                         areaProps.x + areaProps.width,
  //                         areaProps.y + areaProps.height,
  //                       ]}
  //                       stroke="#4AFFFF"
  //                       strokeWidth={1}
  //                       dash={!highlightEdges.right ? [2, 2] : undefined}
  //                       listening={false}
  //                     />

  //                     <Line
  //                       points={[
  //                         areaProps.x,
  //                         areaProps.y + areaProps.height,
  //                         areaProps.x + areaProps.width,
  //                         areaProps.y + areaProps.height,
  //                       ]}
  //                       stroke="#4AFFFF"
  //                       strokeWidth={1}
  //                       dash={!highlightEdges.bottom ? [2, 2] : undefined}
  //                       listening={false}
  //                     />

  //                     <Line
  //                       points={[areaProps.x, areaProps.y, areaProps.x, areaProps.y + areaProps.height]}
  //                       stroke="#4AFFFF"
  //                       strokeWidth={1}
  //                       dash={!highlightEdges.left ? [2, 2] : undefined}
  //                       listening={false}
  //                     />

  //                     <>
  //                       <Rect
  //                         x={areaProps.x}
  //                         y={areaProps.y + areaProps.height + 2}
  //                         width={areaProps.width}
  //                         height={18}
  //                         fill="#3fa3a6"
  //                         dash={[2, 2]}
  //                         listening={false}
  //                       />

  //                       <Text
  //                         x={areaProps.x}
  //                         y={areaProps.y + areaProps.height + 5.5}
  //                         width={areaProps.width}
  //                         text="Print area"
  //                         align="center"
  //                         verticalAlign="middle"
  //                         fontSize={12}
  //                         fill="white"
  //                         listening={false}
  //                       />
  //                     </>
  //                   </Group>
  //                 )}

  //                 {selectedImage && (
  //                   <>
  //                     <Transformer
  //                       ref={trRef}
  //                       flipEnabled={false}
  //                       centeredScaling
  //                       keepRatio
  //                       borderDash={[2.5, 2.5]}
  //                       borderStrokeWidth={0.5}
  //                       borderStroke="#4AFFFF"
  //                       anchorFill="#4AFFFF"
  //                       anchorStroke="#4AFFFF"
  //                       anchorSize={8}
  //                       anchorCornerRadius={6}
  //                       rotateAnchorOffset={18}
  //                       rotationSnaps={[0, 90, 180, 270]}
  //                       opacity={opacity}
  //                       enabledAnchors={['top-left', 'top-right', 'bottom-left', 'bottom-right']}
  //                       boundBoxFunc={(oldBox, newBox) => {
  //                         // limit resize
  //                         if (Math.abs(newBox.width) < 5 || Math.abs(newBox.height) < 5) {
  //                           return oldBox;
  //                         }
  //                         return newBox;
  //                       }}
  //                     />

  //                     {/* Icon Delete */}
  //                     <Group
  //                       ref={deleteGroupRef}
  //                       absolutePosition={deleteIconPosition}
  //                       onClick={(e) => {
  //                         e.cancelBubble = true;

  //                         const stage = e.target.getStage();

  //                         if (stage) {
  //                           stage.container().style.cursor = 'default';
  //                         }

  //                         setUploadedDesign(null);
  //                         setSelectedImage(false);
  //                         setImageProps(initialImagePosition);
  //                         setDeleteIconPosition(initialDeleteIconPosition);
  //                       }}
  //                       onMouseEnter={handleDeleteMouseEnter}
  //                       onMouseLeave={handleResetCursor}
  //                       opacity={opacity}
  //                     >
  //                       <Circle
  //                         width={18}
  //                         height={18}
  //                         fill="#f14931"
  //                         onClick={(e) => {
  //                           e.cancelBubble = true;

  //                           setUploadedDesign(null);
  //                           setSelectedImage(false);
  //                           setImageProps(initialImagePosition);
  //                           setDeleteIconPosition(initialDeleteIconPosition);
  //                         }}
  //                       />
  //                       <Text
  //                         text="⨯"
  //                         offsetX={5.5}
  //                         offsetY={14}
  //                         fontSize={24}
  //                         fontStyle="bold"
  //                         fill="#fff9ff"
  //                         align="center"
  //                         verticalAlign="middle"
  //                         fillAfterStrokeEnabled
  //                         listening={false}
  //                       />
  //                     </Group>
  //                   </>
  //                 )}
  //               </>
  //             )}
  //           </Layer>
  //         </Stage>
  //       </TshirtContainer>
  //     ),
  //   },
  //   {
  //     key: 'back',
  //     label: 'Back',
  //     children: (
  //       <TshirtContainer style={{ backgroundColor: tshirtColor }}>
  //         <Stage width={stageWidth} height={stageHeight} onMouseDown={checkDeselect} onTouchStart={checkDeselect}>
  //           <Layer>
  //             <KonvaImage image={image} width={400} height={400} />

  //             {uploadedImage && (
  //               <>
  //                 <Group
  //                   clip={{
  //                     x: areaProps.x,
  //                     y: areaProps.y,
  //                     width: areaProps.width,
  //                     height: areaProps.height,
  //                   }}
  //                 >
  //                   <KonvaImage
  //                     ref={shapeRef}
  //                     image={uploadedImage}
  //                     {...imageProps}
  //                     name="interaction-image"
  //                     draggable
  //                     onMouseDown={handleMouseDown}
  //                     onMouseUp={handleMouseUp}
  //                     onDragEnd={handleDragEnd}
  //                     onDragStart={handleDragStart}
  //                     onDragMove={handleDragMove}
  //                     onTransform={(e: Konva.KonvaEventObject<Event>) => {
  //                       e.cancelBubble = true;

  //                       updateDeleteIconPosition();
  //                       setSelectedImage(true);
  //                       setIsOuterAreaActive(true);
  //                     }}
  //                     onTransformEnd={handleTransformEnd}
  //                     onMouseEnter={handleImageMouseEnter}
  //                     onMouseLeave={handleResetCursor}
  //                   />
  //                 </Group>
  //                 {isOuterAreaActive && (
  //                   <Group>
  //                     <Line
  //                       points={[areaCenter.x, areaProps.y, areaCenter.x, areaProps.y + areaProps.height]}
  //                       stroke={'#4AFFFF'}
  //                       strokeWidth={1}
  //                       dash={!highlightEdges.centerColumn ? [2, 2] : undefined}
  //                       listening={false}
  //                     />

  //                     <Line
  //                       points={[areaProps.x, areaCenter.y, areaProps.x + areaProps.width, areaCenter.y]}
  //                       stroke={'#4AFFFF'}
  //                       strokeWidth={1}
  //                       dash={!highlightEdges.centerRow ? [2, 2] : undefined}
  //                       listening={false}
  //                     />

  //                     <Line
  //                       points={[areaProps.x, areaProps.y, areaProps.x + areaProps.width, areaProps.y]}
  //                       stroke="#4AFFFF"
  //                       strokeWidth={1}
  //                       dash={!highlightEdges.top ? [2, 2] : undefined}
  //                       listening={false}
  //                     />

  //                     <Line
  //                       points={[
  //                         areaProps.x + areaProps.width,
  //                         areaProps.y,
  //                         areaProps.x + areaProps.width,
  //                         areaProps.y + areaProps.height,
  //                       ]}
  //                       stroke="#4AFFFF"
  //                       strokeWidth={1}
  //                       dash={!highlightEdges.right ? [2, 2] : undefined}
  //                       listening={false}
  //                     />

  //                     <Line
  //                       points={[
  //                         areaProps.x,
  //                         areaProps.y + areaProps.height,
  //                         areaProps.x + areaProps.width,
  //                         areaProps.y + areaProps.height,
  //                       ]}
  //                       stroke="#4AFFFF"
  //                       strokeWidth={1}
  //                       dash={!highlightEdges.bottom ? [2, 2] : undefined}
  //                       listening={false}
  //                     />

  //                     <Line
  //                       points={[areaProps.x, areaProps.y, areaProps.x, areaProps.y + areaProps.height]}
  //                       stroke="#4AFFFF"
  //                       strokeWidth={1}
  //                       dash={!highlightEdges.left ? [2, 2] : undefined}
  //                       listening={false}
  //                     />

  //                     <>
  //                       <Rect
  //                         x={areaProps.x}
  //                         y={areaProps.y + areaProps.height + 2}
  //                         width={areaProps.width}
  //                         height={18}
  //                         fill="#3fa3a6"
  //                         dash={[2, 2]}
  //                         listening={false}
  //                       />

  //                       <Text
  //                         x={areaProps.x}
  //                         y={areaProps.y + areaProps.height + 5.5}
  //                         width={areaProps.width}
  //                         text="Print area"
  //                         align="center"
  //                         verticalAlign="middle"
  //                         fontSize={12}
  //                         fill="white"
  //                         listening={false}
  //                       />
  //                     </>
  //                   </Group>
  //                 )}

  //                 {selectedImage && (
  //                   <>
  //                     <Transformer
  //                       ref={trRef}
  //                       flipEnabled={false}
  //                       centeredScaling
  //                       keepRatio
  //                       borderDash={[2.5, 2.5]}
  //                       borderStrokeWidth={0.5}
  //                       borderStroke="#4AFFFF"
  //                       anchorFill="#4AFFFF"
  //                       anchorStroke="#4AFFFF"
  //                       anchorSize={8}
  //                       anchorCornerRadius={6}
  //                       rotateAnchorOffset={18}
  //                       rotationSnaps={[0, 90, 180, 270]}
  //                       opacity={opacity}
  //                       enabledAnchors={['top-left', 'top-right', 'bottom-left', 'bottom-right']}
  //                       boundBoxFunc={(oldBox, newBox) => {
  //                         // limit resize
  //                         if (Math.abs(newBox.width) < 5 || Math.abs(newBox.height) < 5) {
  //                           return oldBox;
  //                         }
  //                         return newBox;
  //                       }}
  //                     />

  //                     {/* Icon Delete */}
  //                     <Group
  //                       ref={deleteGroupRef}
  //                       absolutePosition={deleteIconPosition}
  //                       onClick={(e) => {
  //                         e.cancelBubble = true;

  //                         const stage = e.target.getStage();

  //                         if (stage) {
  //                           stage.container().style.cursor = 'default';
  //                         }

  //                         setUploadedDesign(null);
  //                         setSelectedImage(false);
  //                         setImageProps(initialImagePosition);
  //                         setDeleteIconPosition(initialDeleteIconPosition);
  //                       }}
  //                       onMouseEnter={handleDeleteMouseEnter}
  //                       onMouseLeave={handleResetCursor}
  //                       opacity={opacity}
  //                     >
  //                       <Circle
  //                         width={18}
  //                         height={18}
  //                         fill="#f14931"
  //                         onClick={(e) => {
  //                           e.cancelBubble = true;

  //                           setUploadedDesign(null);
  //                           setSelectedImage(false);
  //                           setImageProps(initialImagePosition);
  //                           setDeleteIconPosition(initialDeleteIconPosition);
  //                         }}
  //                       />
  //                       <Text
  //                         text="⨯"
  //                         offsetX={5.5}
  //                         offsetY={14}
  //                         fontSize={24}
  //                         fontStyle="bold"
  //                         fill="#fff9ff"
  //                         align="center"
  //                         verticalAlign="middle"
  //                         fillAfterStrokeEnabled
  //                         listening={false}
  //                       />
  //                     </Group>
  //                   </>
  //                 )}
  //               </>
  //             )}
  //           </Layer>
  //         </Stage>
  //       </TshirtContainer>
  //     ),
  //   },
  //   {
  //     key: 'left-sleeve',
  //     label: 'Left Sleeve',
  //     children: (
  //       <TshirtContainer style={{ backgroundColor: tshirtColor }}>
  //         <Stage width={400} height={400} ref={stageRef}>
  //           <Layer>
  //             <KonvaImage image={image} width={400} height={400} />
  //           </Layer>
  //         </Stage>
  //       </TshirtContainer>
  //     ),
  //   },
  //   {
  //     key: 'right-sleeve',
  //     label: 'Right Sleeve',
  //     children: (
  //       <TshirtContainer style={{ backgroundColor: tshirtColor }}>
  //         <Stage width={400} height={400} ref={stageRef}>
  //           <Layer>
  //             <KonvaImage image={image} width={400} height={400} />
  //           </Layer>
  //         </Stage>
  //       </TshirtContainer>
  //     ),
  //   },
  // ];

  const [open, setOpen] = useState(false);

  const handleOpenModal = () => {
    setOpen(true);
  };

  const dummyTabsData = [
    {
      key: 'front',
      label: 'Front',
    },
    {
      key: 'back',
      label: 'Back',
    },
    {
      key: 'left-sleeve',
      label: 'Left Sleeve',
    },
    {
      key: 'right-sleeve',
      label: 'Right Sleeve',
    },
  ];

  const getTabsByTab = useMemo(() => {
    return dummyTabsData.find((tab) => tab.key === currentTab)?.label || '';
  }, [currentTab]);

  const dummyColors = [
    {
      type: 'front',
      colors: [
        { name: 'Red', code: '#FF0000', image: tshirtRedFront },
        { name: 'Blue', code: '#0000FF', image: tshirtBlueFront },
      ],
    },
    {
      type: 'back',
      colors: [
        { name: 'Red', code: '#FF0000', image: tshirtRedBack },
        { name: 'Blue', code: '#0000FF', image: tshirtBlueBack },
      ],
    },
    {
      type: 'left-sleeve',
      colors: [
        { name: 'Red', code: '#FF0000', image: tshirtRedLeft },
        { name: 'Blue', code: '#0000FF', image: tshirtBlueLeft },
      ],
    },
    {
      type: 'right-sleeve',
      colors: [
        { name: 'Red', code: '#FF0000', image: tshirtRedRight },
        { name: 'Blue', code: '#0000FF', image: tshirtBlueRight },
      ],
    },
  ];

  const getColorByTab = useMemo(() => {
    return dummyColors.find((color) => color.type === currentTab)?.colors || [];
  }, [currentTab]);

  console.log({ getColorByTab });

  // product
  const dummyGetProductReviewFilters = {
    colors: [
      {
        name: 'Red',
        code: '#FF0000',
      },
      {
        name: 'Blue',
        code: '#0000FF',
      },
      {
        name: 'Yellow',
        code: '#FFFF00',
      },
      {
        name: 'Green',
        code: '#008000',
      },
      {
        name: 'Purple',
        code: '#800080',
      },
      {
        name: 'Orange',
        code: '#FFA500',
      },
    ],
    sizes: ['S', 'M', 'L', 'XL', '2XL', '3XL', '4XL', '5XL'],
    techniques: [
      {
        code: 'T-SHIRT',
        name: 'DTG printing',
      },
      {
        code: 'EMBROIDERY',
        name: 'Embroidery',
      },
    ],
  };

  return (
    <BaseLayout>
      <>
        <PageTitle>Design Maker</PageTitle>

        <MainContainer>
          <Sidebar>
            <h3>Technique</h3>
            <Button type="primary">Printing</Button>
            <Button style={{ marginLeft: '10px' }}>Embroidery</Button>

            <h3 style={{ marginTop: '20px' }}>Color</h3>
            <Select defaultValue={getColorByTab[0].code} style={{ width: 120 }} onChange={handleColorChange}>
              {getColorByTab.map((color) => (
                <Option key={color.code} value={color.code}>
                  {color.name}
                </Option>
              ))}
              []
            </Select>

            <h3 style={{ marginTop: '20px' }}>Size</h3>
            <Select defaultValue="M" style={{ width: 120 }}>
              <Option value="S">S</Option>
              <Option value="M">M</Option>
              <Option value="L">L</Option>
              <Option value="XL">XL</Option>
            </Select>

            <h3 style={{ marginTop: '20px' }}>Upload Design</h3>
            <Upload
              name="file"
              showUploadList={false}
              beforeUpload={(file) => {
                handleUpload({ file });
                return false;
              }}
            >
              <Button icon={<UploadOutlined />}>Click to Upload</Button>
            </Upload>

            <Button onClick={handleOpenModal}>Click to Modal</Button>
          </Sidebar>

          {/* <Tabs defaultActiveKey="front" onChange={handleTabChange} items={tabItems} /> */}

          {/* <ContentDesign
            currentTab={currentTab}
            designs={designs}
            onTabChange={handleTabChange}
            onDesignUpdate={updateDesign}
            stageRef={stageRef}
            initialImagePosition={initialImagePosition}
            getColorByTab={getColorByTab}
          /> */}

          <RightToolsBar>
            <Button icon={<ScissorOutlined />} onClick={() => setCropModalVisible(true)}>
              Crop
            </Button>

            <Button type="primary" onClick={showModal}>
              Preview
            </Button>
          </RightToolsBar>

          <Modal
            open={cropModalVisible}
            onCancel={() => setCropModalVisible(false)}
            onOk={handleApplyCrop}
            title="Crop Design"
          >
            {uploadedDesign && (
              <Cropper
                image={uploadedDesign}
                crop={cropSettings.crop}
                zoom={cropSettings.zoom}
                aspect={cropSettings.aspect}
                onCropChange={(crop) => setCropSettings((prev) => ({ ...prev, crop }))}
                onZoomChange={(zoom) => setCropSettings((prev) => ({ ...prev, zoom }))}
              />
            )}
          </Modal>

          <Modal title="Preview" open={modalVisible} onOk={handleOk} onCancel={handleCancel}>
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
              <Stage width={stageWidth} height={stageHeight}>
                <Layer>
                  {image && <KonvaImage image={image} width={400} height={400} />}

                  {uploadedImage && (
                    <Group
                      clip={{
                        x: areaProps.x,
                        y: areaProps.y,
                        width: areaProps.width,
                        height: areaProps.height,
                      }}
                    >
                      <KonvaImage {...designs[currentTab]} image={uploadedImage} />
                    </Group>
                  )}
                </Layer>
              </Stage>
            </div>
          </Modal>

          {/* <DesignMakerModal visible={open} onClose={() => setOpen(false)} /> */}
        </MainContainer>
      </>
    </BaseLayout>
  );
};

export default DesignMakerPage;
