import { createAsyncThunk, createSelector, createSlice } from '@reduxjs/toolkit';
import { getProduct } from '@app/api/product.api';
import { withDelay } from '@app/utils/utils';

export const getProductList = createAsyncThunk('product/list', async (formData, { rejectWithValue }) => {
  try {
    const response = await withDelay(getProduct(formData));

    return response;
  } catch (err) {
    return rejectWithValue(err);
  }
});

export const initialData = {
  size: 12,
  sort: [],
  totalElements: 0,
  totalPages: 0,
  number: 0,
  content: [],
};

const ProductSlice = createSlice({
  name: 'product',
  initialState: {
    product: initialData,
    loading: false,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getProductList.pending, (state) => {
        state.loading = true;
      })
      .addCase(getProductList.fulfilled, (state, action) => {
        state.product = action.payload;
        state.loading = false;
      })
      .addCase(getProductList.rejected, (state) => {
        state.loading = false;
      });
  },
});

const { actions, reducer } = ProductSlice;

export default reducer;

export const productData = (state) => state.product;

export const productDataSelector = createSelector([productData], (state) => state);
