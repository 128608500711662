import React, { useState, useEffect, useMemo, useRef } from 'react';
import styled from 'styled-components';
import { Modal, Button, Checkbox, Radio, Tooltip, Input, Tabs, Upload } from 'antd';
import {
  InboxOutlined,
  PictureOutlined,
  FontSizeOutlined,
  AppstoreOutlined,
  FileOutlined,
  UndoOutlined,
  RedoOutlined,
  UploadOutlined,
} from '@ant-design/icons';
import { ReactComponent as UndoIcon } from '@app/assets/icons/undo.svg';
import { ReactComponent as RedoIcon } from '@app/assets/icons/redo.svg';

import TabContent from '@app/pages/DesignMaker/ContentDesign';
import { v4 as uuidv4 } from 'uuid';

const sidebarTabs = [
  { name: 'Product', icon: <InboxOutlined /> },
  { name: 'Uploads', icon: <PictureOutlined /> },
  { name: 'Text', icon: <FontSizeOutlined /> },
  { name: 'Add Graphics', icon: <AppstoreOutlined /> },
  { name: 'Templates', icon: <FileOutlined /> },
];

export const imageWidth = 30;
export const imageHeight = 30;
export const textWidth = 100;
export const textHeight = 30;

export const initialImagePosition = (areaCenter, imageWidth, imageHeight) => {
  return {
    x: areaCenter.x - imageWidth / 2,
    y: areaCenter.y - imageHeight / 2,
    width: imageWidth,
    height: imageHeight,
    rotation: 0,
  };
};

const getInitialImagePosition = ({ area, imagesMaker }) => {
  const { image, text } = imagesMaker;

  const areaCenter = {
    x: area.left + area.width / 2,
    y: area.top + area.height / 2,
  };

  return {
    image: {
      ...initialImagePosition(areaCenter, imageWidth, imageHeight),
      src: image?.src || '',
      ...(image?.left && { x: image.left }),
      ...(image?.top && { x: image.top }),
      ...(image?.width && { x: image.width }),
      ...(image?.height && { x: image.height }),
      ...(image?.rotate && { x: image.rotate }),
    },
    text: {
      ...initialImagePosition(areaCenter, textWidth, textHeight),
      content: text?.content || '',
      ...(text?.left && { x: text.left }),
      ...(text?.top && { x: text.top }),
      ...(text?.width && { x: text.width }),
      ...(text?.height && { x: text.height }),
      ...(text?.rotate && { x: text.rotate }),
    },
  };
};

const initialFromData = {
  productId: null,
  name: '',
  avatar: '',
  colors: [],
  unitPrices: [],
  template: {
    uuid: null,
    unitPrices: [],
    colors: [],
    type: 'dtg',
    designMaker: [
      {
        src: '',
        position: '',
        imagesMaker: {
          image: {
            src: '',
            width: null,
            height: null,
            top: null,
            left: null,
          },
          text: {
            content: '',
            width: null,
            height: null,
            top: null,
            left: null,
          },
        },
        area: {
          width: null,
          height: null,
          top: null,
          left: null,
        },
      },
    ],
  },
};

const DesignMakerModal = ({ visible, onClose, onSaveTemplate, data }) => {
  console.log({ data });

  const [selectedSize, setSelectedSize] = useState([]);
  const [selectedColor, setSelectedColor] = useState('');
  const [technique, setTechnique] = useState('');
  const [activeSidebarTab, setActiveSidebarTab] = useState('Product');
  const [currentTab, setCurrentTab] = useState('');
  const fileInputRef = useRef(null);

  const [tabImages, setTabImages] = useState({});
  const [canvasImage, setCanvasImage] = useState({});

  const [initialData, setInitialData] = useState(initialFromData);

  const handleResetState = () => {
    setCanvasImage({});
    setTabImages({});
    setInitialData(initialFromData);
    setCurrentTab('');
    setSelectedSize([]);
    setSelectedColor('');
  };

  useEffect(() => {
    const sourceData = data?.template?.designMaker;

    if (sourceData?.length > 0) {
      setCurrentTab(sourceData[0].position);
    }
  }, [data?.template?.designMaker, setCurrentTab]);

  useEffect(() => {
    if (!data) return;

    setInitialData((prev) => ({
      ...prev,
      ...data,
    }));
  }, [data]);

  useEffect(() => {
    const sourceData = data?.template?.designMaker;

    if (sourceData?.length > 0) {
      if (Object.keys(tabImages).length === 0) {
        const initialTabImages = {};

        sourceData?.forEach((item) => {
          initialTabImages[item.position] = getInitialImagePosition(item);
        });

        setTabImages(initialTabImages);
      }
    }
  }, [data, tabImages, setTabImages]);

  useEffect(() => {
    if (visible) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }
    return () => {
      document.body.style.overflow = 'auto';
    };
  }, [visible]);

  const handleTabChange = (key) => {
    setCurrentTab(key);
  };

  const tabItems = useMemo(() => {
    const sourceData = data?.template?.designMaker;

    return sourceData?.map((item) => {
      return {
        key: item.position,
        label: item.position,
        children: (
          <TabContent item={item} tabImages={tabImages} setTabImages={setTabImages} setCanvasImage={setCanvasImage} />
        ),
      };
    });
  }, [data?.template?.designMaker, tabImages, setTabImages, setCanvasImage]);

  const handleSidebarTabClick = (name) => {
    if (name !== 'Uploads') {
      setActiveSidebarTab(name);
    }

    if (name === 'Uploads' && fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handleFileUpload = (event) => {
    const files = event.target.files;

    if (!files || files.length === 0) {
      console.warn('No file selected');
      return;
    }

    const file = files[0];

    // Kiểm tra xem file có phải là ảnh không
    if (!file.type.startsWith('image/')) {
      console.warn('Please upload an image file');
      return;
    }

    const reader = new FileReader();
    reader.onload = (e) => {
      const base64String = e.target?.result;
      if (!base64String) {
        console.error('Failed to read file');
        return;
      }

      setTabImages((prev) => ({
        ...prev,
        [currentTab]: {
          ...prev[currentTab],
          image: {
            ...prev[currentTab]['image'],
            src: base64String,
          },
        },
      }));
    };

    reader.onerror = () => {
      console.error('Error reading file');
    };

    reader.readAsDataURL(file);
    event.target.value = null; // Reset input
  };

  const handleColor = (data) => {
    setSelectedColor(data.hex);

    setInitialData((prev) => ({
      ...prev,
      template: {
        ...prev.template,
        colors: [data],
      },
    }));
  };

  const handleSaveTemplate = (e) => {
    e.preventDefault();

    const formData = {
      ...initialData,
      avatar: canvasImage || '',
      template: {
        ...initialData.template,
        uuid: uuidv4(),
        // unitPrices: data?.unitPrices || [],
        // colors: res?.colors ?? [],
        // designMaker:
      },
    };

    console.log({ formData });
    // onSaveTemplate && onSaveTemplate();
  };

  const [textInput, setTextInput] = useState(''); // State để lưu giá trị input

  // Hàm xử lý khi thêm văn bản
  const handleAddText = () => {
    if (textInput.trim()) {
      setTabImages((prev) => {
        return {
          ...prev,
          [currentTab]: {
            ...prev[currentTab],
            text: {
              ...prev[currentTab]['text'],
              content: textInput,
            },
          },
        };
      });

      setTextInput('');
    }
  };

  const handleClose = () => {
    onClose();
    handleResetState();
  };

  return (
    <StyledModal
      open={visible}
      onCancel={handleClose}
      footer={null}
      width="100%"
      centered
      keyboard={false} // Ngăn bàn phím tự focus
      focusTriggerAfterClose={false} // Không trả focus sau khi đóng
    >
      <WrapperInput>
        <h3>{data?.name ?? ''}</h3>

        <WrapperBack>
          <Tooltip placement="top" title="Undo">
            <WrapperUndoAndRedo>
              <UndoIcon />
            </WrapperUndoAndRedo>
          </Tooltip>

          <Tooltip placement="top" title="Redo">
            <WrapperUndoAndRedo>
              <RedoIcon />
            </WrapperUndoAndRedo>
          </Tooltip>
        </WrapperBack>
      </WrapperInput>

      <Container>
        <Sidebar>
          {sidebarTabs.map(({ name, icon }) => (
            <SidebarTab key={name} active={activeSidebarTab === name} onClick={() => handleSidebarTabClick(name)}>
              {icon}
              <SidebarName>{name}</SidebarName>
            </SidebarTab>
          ))}

          {/* Input file ẩn */}
          <input
            type="file"
            accept="image/*"
            ref={fileInputRef}
            style={{ display: 'none' }}
            onChange={handleFileUpload}
          />
        </Sidebar>

        <MainContent>
          {activeSidebarTab === 'Product' && (
            <ProductInfo>
              <div>
                <h4>Technique</h4>
                <Radio.Group value={technique} onChange={(e) => setTechnique(e.target.value)}>
                  <Radio.Button value="dtg">DTG printing</Radio.Button>
                  <Radio.Button value="embroidery">
                    Embroidery
                    <span
                      style={{
                        background: '#e40f0a',
                        borderRadius: '10px',
                        padding: '2px 8px',
                        fontSize: '12px',
                        color: '#fff',
                        marginLeft: '4px',
                        fontWeight: 'bold',
                      }}
                    >
                      New
                    </span>
                  </Radio.Button>
                </Radio.Group>
              </div>

              <div>
                <h4>Color</h4>
                <ColorPalette>
                  {data?.colors?.map(({ name, hex }) => (
                    <Tooltip title={`${name} (${hex})`} key={hex}>
                      <ColorCircle
                        color={hex}
                        selected={hex === selectedColor}
                        onClick={() => handleColor({ name, hex })}
                      />
                    </Tooltip>
                  ))}
                </ColorPalette>
              </div>

              <div>
                <h4>Size</h4>
                <SizeContainer>
                  {data?.unitPrices?.map(({ size }) => (
                    <Checkbox
                      key={size}
                      checked={selectedSize.includes(size)}
                      onChange={(e) => {
                        const newSelected = e.target.checked
                          ? [...selectedSize, size]
                          : selectedSize.filter((s) => s !== size);
                        setSelectedSize(newSelected);
                      }}
                    >
                      {size}
                    </Checkbox>
                  ))}
                </SizeContainer>
              </div>
            </ProductInfo>
          )}

          {activeSidebarTab === 'Text' && (
            <div style={{ padding: '16px' }}>
              <h4>Add Text</h4>
              <Input
                value={textInput}
                onChange={(e) => setTextInput(e.target.value)}
                onPressEnter={handleAddText}
                placeholder="Enter text here"
                style={{ marginBottom: '8px' }}
              />
              <Button type="primary" onClick={handleAddText}>
                Add to Canvas
              </Button>
            </div>
          )}

          <DesignArea tabIndex={0}>
            <Tabs activeKey={currentTab} tabPosition="top" animated onChange={handleTabChange} items={tabItems} />
          </DesignArea>
        </MainContent>
      </Container>

      <WrapperFooter>
        <WrapperFooterLeft>
          {canvasImage?.[currentTab] ? (
            <img src={canvasImage[currentTab]} width={50} height={50} />
          ) : (
            <span style={{ width: 50, height: 50, background: '#ddd', borderRadius: '6px', opacity: 0.8 }}></span>
          )}

          <div>
            <p>{data?.name || ''}</p>
            <p>$6.05</p>
          </div>
        </WrapperFooterLeft>
        <Button type="primary" onClick={handleSaveTemplate}>
          Save to templates
        </Button>
      </WrapperFooter>
    </StyledModal>
  );
};

const WrapperInput = styled.div`
  height: 110px;
  padding: 24px 60px 24px 24px;

  border-bottom: 1px solid #ddd;

  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const WrapperBack = styled.div`
  display: flex;
  gap: 4px;
  align-items: center;
  padding-right: 16px;
  border-right: 1px solid #ddd;
`;

const WrapperUndoAndRedo = styled.div`
  padding: 4px 8px;

  &:hover {
    background: #ddd;
    padding: 4px 8px;
    border-radius: 4px;
    cursor: pointer;
  }
  & svg {
    stroke: #212121;
  }
`;

const CustomInput = styled(Input)`
  width: 180px;
  height: 54px;
`;

const StyledModal = styled(Modal)`
  max-width: 100%;

  .ant-modal-body {
    padding: 0 0 24px 0;
  }

  .ant-modal-content {
    padding: 0;
    height: 100vh;
    border-radius: 0;
    max-width: none;
    max-height: none;
    display: flex;
    flex-direction: column;

    &[inert] {
      pointer-events: none;
      opacity: 0.5;
    }

    & .ant-modal-close {
      top: 26px;
    }
  }
`;

const Container = styled.div`
  display: flex;
  height: calc(100vh - 224px);
  padding-right: 24px;
`;

const Sidebar = styled.div`
  width: 90px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
  padding: 8px;
  border-right: 1px solid #ddd;
`;

const SidebarTab = styled.div`
  min-height: 60px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  padding: 10px;
  font-size: 12px;
  border-radius: 10px;
  background: ${(props) => (props.active ? '#f2f2f2' : 'transparent')};
  &:hover {
    background: rgb(95% 95% 95%);
  }
`;

const SidebarName = styled.span`
  word-break: break-word;
  text-align: center;
`;

const MainContent = styled.div`
  display: flex;
  flex: 1;
`;

const ProductInfo = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;

  min-width: 500px;
  padding: 16px;
  border-right: 1px solid #ddd;
`;

const DesignArea = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  outline: none;
`;

const TShirtDisplay = styled.div`
  width: 300px;
  height: 400px;
  background: ${(props) => props.color};
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px dashed gray;
  border-radius: 8px;
  position: relative;
  overflow-x: auto;

  img {
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

const UploadArea = styled.div`
  text-align: center;
  color: #0073e6;
  cursor: pointer;
  position: relative;
  z-index: 2;
`;

const ColorPalette = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
  margin: 10px 0;
`;

const ColorCircle = styled.div`
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background: ${(props) => props.color};
  border: ${(props) => (props.selected ? '2px solid #000' : '1px solid #ccc')};
  cursor: pointer;
`;

const SizeContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 4x;

  & .ant-checkbox-inner {
    border-radius: 4px;
  }
`;

const WrapperFooter = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: end;
  height: 90px;
  border-top: 1px solid #f0f0f0;
  padding-inline: 24px;
`;

const WrapperFooterLeft = styled.div`
  display: flex;
  align-items: center;
  gap: 24px;

  & div {
    position: relative;
    color: #555555;
    font-size: 14px;

    &::before {
      position: absolute;
      content: '';
      width: 1.5px;
      height: 100%;
      background: #e5e5e5;
      left: -12px;
    }
  }

  & p {
    margin-bottom: 0;

    &:last-child {
      color: #222222;
      font-size: 15px;
      font-weight: bold;
      font-variant-numeric: proportional-nums;
    }
  }
`;

export default DesignMakerModal;
