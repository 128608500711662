import styled from 'styled-components';

export const ListItemColor = styled.div<{ $customColor?: string }>`
  width: 16px;
  height: 16px;
  border-radius: 50%;

  display: flex;
  flex-shrink: 0;
  ${({ $customColor }) => $customColor && { backgroundColor: $customColor ?? 'while' }};
`;

export const ListColor = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  cursor: pointer;
  flex: 1;
`;
