import React, { useRef, useEffect, useMemo, useState } from 'react';
import Konva from 'konva';
import useImage from 'use-image';
import { Stage, Layer, Image as KonvaImage, Rect, Text, Circle, Transformer, Line, Group } from 'react-konva';

import { TshirtContainer } from '../DesignMaker.styles';
import {
  initialImagePosition,
  imageWidth,
  imageHeight,
  textWidth,
  textHeight,
} from '@app/pages/DesignMaker/DesignMakerModal';

export type areaProps = {
  x: number;
  y: number;
  width: number;
  height: number;
};

interface TabContentProps {
  item: {
    src: string;
    imagesMaker?: {
      image: {
        src: string;
        width: number;
        height: number;
        top: number;
        left: number;
        rotate: number;
      };
      text?: {
        content: string;
        width: number;
        height: number;
        top: number;
        left: number;
        rotate: number;
      };
    };
    position: string;
    area: {
      width: number;
      height: number;
      top: number;
      left: number;
    };
  };
  tabImages: any;
  setTabImages: React.Dispatch<React.SetStateAction<any>>;
  setCanvasImage: React.Dispatch<React.SetStateAction<any>>;
}

const stageWidth = 400;
const stageHeight = 400;
const snapThreshold = 5;

type InitialDeleteIconImagePosition = {
  x: number;
  y: number;
};

const TabContent = ({ item, tabImages, setTabImages, setCanvasImage }: TabContentProps) => {
  const { area, position } = item;

  const { width, height, top, left } = area;

  const areaProps = useMemo(
    () => ({
      width,
      height,
      x: left,
      y: top,
    }),
    [height, left, top, width],
  );

  const areaCenter = useMemo(
    () => ({
      x: areaProps.x + areaProps.width / 2,
      y: areaProps.y + areaProps.height / 2,
    }),
    [areaProps],
  );

  const stageRef = useRef<Konva.Stage | null>(null);
  const imageRef = useRef<Konva.Image | null>(null);
  const textRef = useRef<Konva.Text | null>(null);
  const trRef = useRef<Konva.Transformer | null>(null);

  const [selectedNode, setSelectedNode] = useState<'image' | 'text' | null>(null);

  const [isOuterAreaActive, setIsOuterAreaActive] = useState(false);

  const [highlightEdges, setHighlightEdges] = useState({
    top: false,
    right: false,
    bottom: false,
    left: false,
    center: false,
    centerColumn: false,
    centerRow: false,
  });

  const [imageDesign] = useImage(tabImages?.[position]?.image?.src, 'anonymous');
  const [baseImage] = useImage(item?.src, 'anonymous');

  const [deleteIconImagePosition, setDeleteIconImagePosition] = useState<InitialDeleteIconImagePosition | null>(null);

  const deleteGroupRef = useRef<Konva.Group>(null);
  const [opacity, setOpacity] = useState<number>(1);

  const exportCanvasImage = () => {
    if (!stageRef.current) {
      return null;
    }

    const areaGroup = stageRef.current.findOne(
      (node: any) => node instanceof Konva.Group && node.children.some((child) => child instanceof Konva.Line),
    );

    if (trRef.current && selectedNode) trRef.current.visible(false);
    if (deleteGroupRef.current) deleteGroupRef.current.visible(false);
    if (areaGroup) areaGroup.visible(false);

    stageRef.current.batchDraw();
    const canvasImage = stageRef.current.toDataURL();

    if (trRef.current && selectedNode) trRef.current.visible(true);
    if (deleteGroupRef.current) deleteGroupRef.current.visible(selectedNode !== null);
    if (areaGroup) areaGroup.visible(isOuterAreaActive);
    stageRef.current.batchDraw();

    return canvasImage;
  };

  useEffect(() => {
    if (!stageRef.current || !position) return;

    const updateCanvas = () => {
      const newCanvasImage = exportCanvasImage();
      if (newCanvasImage && newCanvasImage !== '') {
        setCanvasImage((prev: any) => ({
          ...prev,
          [position]: newCanvasImage,
        }));
      }
    };

    if (baseImage) {
      requestAnimationFrame(updateCanvas);
    }
  }, [baseImage, position, setCanvasImage]);

  // // Update canvasImage when tab change
  useEffect(() => {
    if (stageRef.current) {
      setTimeout(() => {
        const newCanvasImage = exportCanvasImage();
        setCanvasImage((prev: any) => ({
          ...prev,
          [position]: newCanvasImage || item?.src,
        }));
      }, 300);
    }
  }, [position, item?.src, baseImage, tabImages, setCanvasImage]);

  const onDesignUpdate = (newDesign: any, type: 'image' | 'text') => {
    const width = type === 'image' ? imageWidth : textWidth;
    const height = type === 'image' ? imageHeight : textHeight;

    // if (newDesign === null) {
    //   const initialDeleteIconImagePosition = {
    //     x: areaCenter.x - width / 2 + width,
    //     y: areaCenter.y - height / 2,
    //   };

    //   setDeleteIconImagePosition(initialDeleteIconImagePosition);
    // }

    setTabImages((prev: any) => {
      if (newDesign === null) {
        return {
          ...prev,
          [position]: {
            ...prev[position],
            [type]: {
              ...initialImagePosition(areaCenter, width, height),
              ...(type === 'image' ? { src: '' } : { content: '' }),
            },
          },
        };
      }
      return {
        ...prev,
        [position]: {
          ...prev[position],
          [type]: {
            ...prev[position]?.[type],
            ...newDesign,
          },
        },
      };
    });

    const canvasImage = exportCanvasImage();
    if (canvasImage) {
      setCanvasImage((prev: any) => ({
        ...prev,
        [position]: canvasImage,
      }));
    }
  };

  const handleDelete = () => {
    if (selectedNode === 'image') {
      onDesignUpdate(null, 'image');
    } else if (selectedNode === 'text') {
      onDesignUpdate(null, 'text');
    }
    setSelectedNode(null);
    setDeleteIconImagePosition(null);
  };

  useEffect(() => {
    if (selectedNode && trRef.current) {
      const node = selectedNode === 'image' ? imageRef.current : textRef.current;

      if (node) {
        trRef.current.nodes([node]);
        trRef.current.getLayer()?.batchDraw();

        const initialDeleteIconImagePosition = {
          x: node.attrs.x + node.attrs.width,
          y: node.attrs.y,
        };

        setDeleteIconImagePosition(initialDeleteIconImagePosition);
      }
    }
  }, [selectedNode]);

  // Function to handle clicking and holding the mouse on the image
  const handleMouseDown = (type: 'image' | 'text') => (evt: Konva.KonvaEventObject<MouseEvent>) => {
    evt.cancelBubble = true;
    setIsOuterAreaActive(true);
    setSelectedNode(type);
  };

  // Function to handle mouse release on image
  const handleMouseUp = (e: Konva.KonvaEventObject<MouseEvent>) => {
    e.cancelBubble = true;
    setIsOuterAreaActive(false);
  };

  const handleTransformEnd = (type: 'image' | 'text') => (e: Konva.KonvaEventObject<Event>) => {
    updateDeleteIconPosition();
    setIsOuterAreaActive(false);

    const node = type === 'image' ? imageRef.current : textRef.current;

    if (node) {
      const scaleX = node.scaleX();
      const scaleY = node.scaleY();
      node.scaleX(1);
      node.scaleY(1);

      onDesignUpdate(
        {
          ...(tabImages[position]?.[type] || {}),
          x: node.x(),
          y: node.y(),
          width: node.width() * scaleX,
          height: node.height() * scaleY,
          rotation: node.rotation(),
        },
        type,
      );
    }
  };

  const handleDragMove = (e: Konva.KonvaEventObject<DragEvent>) => {
    e.cancelBubble = true;
    updateDeleteIconPosition();
    setOpacity(0.5);

    const node = e.target;
    const boundingBox = node.getClientRect();

    const rotation = node.rotation();
    const angleRad = (rotation * Math.PI) / 180;

    const width = node.width() * node.scaleX();
    const height = node.height() * node.scaleY();

    const imageCenter = {
      x: node.x() + (width / 2) * Math.cos(angleRad) - (height / 2) * Math.sin(angleRad),
      y: node.y() + (width / 2) * Math.sin(angleRad) + (height / 2) * Math.cos(angleRad),
    };

    const areaCenter = {
      x: areaProps.x + areaProps.width / 2,
      y: areaProps.y + areaProps.height / 2,
    };

    const minDistanceToTop = Math.abs(boundingBox.y - areaProps.y);
    const minDistanceToRight = Math.abs(boundingBox.x + boundingBox.width - (areaProps.x + areaProps.width));
    const minDistanceToBottom = Math.abs(boundingBox.y + boundingBox.height - (areaProps.y + areaProps.height));
    const minDistanceToLeft = Math.abs(boundingBox.x - areaProps.x);

    const snapToTop = minDistanceToTop < snapThreshold;
    const snapToRight = minDistanceToRight < snapThreshold;
    const snapToBottom = minDistanceToBottom < snapThreshold;
    const snapToLeft = minDistanceToLeft < snapThreshold;

    const isNearCenter =
      Math.abs(imageCenter.x - areaCenter.x) < snapThreshold && Math.abs(imageCenter.y - areaCenter.y) < snapThreshold;
    const isNearCenterColumn = Math.abs(imageCenter.x - areaCenter.x) < snapThreshold;
    const isNearCenterRow = Math.abs(imageCenter.y - areaCenter.y) < snapThreshold;

    // Update state
    setHighlightEdges({
      top: snapToTop,
      right: snapToRight,
      bottom: snapToBottom,
      left: snapToLeft,
      center: isNearCenter,
      centerColumn: isNearCenterColumn,
      centerRow: isNearCenterRow,
    });

    // Snapping logic
    let newX = node.x();
    let newY = node.y();

    if (isNearCenter) {
      // Snap to center
      const rotatedOffsetX = (width / 2) * Math.cos(angleRad) - (height / 2) * Math.sin(angleRad);
      const rotatedOffsetY = (width / 2) * Math.sin(angleRad) + (height / 2) * Math.cos(angleRad);

      newX = areaCenter.x - rotatedOffsetX;
      newY = areaCenter.y - rotatedOffsetY;
    } else {
      // Snapping vào trục giữa và các cạnh/góc
      if (isNearCenterColumn) {
        const rotatedOffsetX = (width / 2) * Math.cos(angleRad) - (height / 2) * Math.sin(angleRad);
        newX = areaCenter.x - rotatedOffsetX;
      }
      if (isNearCenterRow) {
        const rotatedOffsetY = (width / 2) * Math.sin(angleRad) + (height / 2) * Math.cos(angleRad);
        newY = areaCenter.y - rotatedOffsetY;
      }

      // Snapping vào các cạnh và góc
      if (snapToTop) {
        newY = areaProps.y - (boundingBox.y - node.y());
        if (snapToLeft) {
          newX = areaProps.x - (boundingBox.x - node.x()); // Top-left
        } else if (snapToRight) {
          newX = areaProps.x + areaProps.width - boundingBox.width - (boundingBox.x - node.x()); // Top-right
        }
      } else if (snapToBottom) {
        newY = areaProps.y + areaProps.height - boundingBox.height - (boundingBox.y - node.y());
        if (snapToLeft) {
          newX = areaProps.x - (boundingBox.x - node.x()); // Bottom-left
        } else if (snapToRight) {
          newX = areaProps.x + areaProps.width - boundingBox.width - (boundingBox.x - node.x()); // Bottom-right
        }
      } else if (snapToLeft) {
        newX = areaProps.x - (boundingBox.x - node.x());
      } else if (snapToRight) {
        newX = areaProps.x + areaProps.width - boundingBox.width - (boundingBox.x - node.x());
      }
    }

    node.position({ x: newX, y: newY });

    const isFullyOutOfArea =
      boundingBox.x + boundingBox.width < areaProps.x ||
      boundingBox.x > areaProps.x + areaProps.width ||
      boundingBox.y + boundingBox.height < areaProps.y ||
      boundingBox.y > areaProps.y + areaProps.height;

    if (isFullyOutOfArea) {
      const rotatedOffsetX = (width / 2) * Math.cos(angleRad) - (height / 2) * Math.sin(angleRad);
      const rotatedOffsetY = (width / 2) * Math.sin(angleRad) + (height / 2) * Math.cos(angleRad);

      setIsOuterAreaActive(false);
      updateDeleteIconPosition();

      // Synchronize location with node
      node.position({
        x: areaCenter.x - rotatedOffsetX,
        y: areaCenter.y - rotatedOffsetY,
      });
      node.stopDrag();
    }
  };

  const handleDragEnd = (type: 'image' | 'text') => (e: Konva.KonvaEventObject<DragEvent>) => {
    updateDeleteIconPosition();
    setOpacity(1);

    const node = e.target;
    onDesignUpdate(
      {
        ...(tabImages[position]?.[type] || {}),
        x: node.x(),
        y: node.y(),
      },
      type,
    );
  };

  const checkDeselect = (e: Konva.KonvaEventObject<MouseEvent | TouchEvent>) => {
    const isTransformer = trRef.current?.getChildren()?.some((child) => child === e.target);

    const interaction = ['interaction-image', 'interaction-text'].includes(e.target.attrs.name);

    const isImageOrText = interaction || e.target.attrs?.text === '⨯' || e.target.attrs?.fill === '#f14931';

    if (!isImageOrText && !isTransformer) setSelectedNode(null);
  };

  const handleImageMouseEnter = (evt: Konva.KonvaEventObject<MouseEvent>) => {
    const stage = evt.target.getStage();

    if (stage) {
      stage.container().style.cursor = 'move';
    }
  };

  const handleDeleteMouseEnter = (evt: Konva.KonvaEventObject<MouseEvent>) => {
    const stage = evt.target.getStage();
    if (stage) stage.container().style.cursor = 'pointer';
  };

  const handleResetCursor = (evt: Konva.KonvaEventObject<MouseEvent>) => {
    const stage = evt.target.getStage();
    if (stage) stage.container().style.cursor = 'default';
  };

  const updateDeleteIconPosition = () => {
    let animationFrameId: number | null = null;
    if (animationFrameId !== null) cancelAnimationFrame(animationFrameId);

    animationFrameId = requestAnimationFrame(() => {
      const transformer = trRef.current;
      const deleteGroup = deleteGroupRef.current;

      if (!transformer || !deleteGroup) return;

      const topRightAnchor = transformer.findOne('.top-right');

      if (topRightAnchor) {
        const anchorPosition = topRightAnchor.absolutePosition();

        deleteGroup.absolutePosition({
          x: anchorPosition.x,
          y: anchorPosition.y,
        });

        setDeleteIconImagePosition({
          x: anchorPosition.x,
          y: anchorPosition.y,
        });
      }

      animationFrameId = null;
    });
  };

  const handleDragStart = (e: Konva.KonvaEventObject<DragEvent>) => {
    updateDeleteIconPosition();
    e.cancelBubble = true;
  };

  return (
    <TshirtContainer style={{ backgroundColor: '#ffffff' }}>
      <Stage
        ref={stageRef}
        width={stageWidth}
        height={stageHeight}
        onMouseDown={checkDeselect}
        onTouchStart={checkDeselect}
      >
        <Layer>
          {baseImage && <KonvaImage image={baseImage} width={stageWidth} height={stageHeight} listening={false} />}

          {(imageDesign || tabImages[position]?.text?.content) && (
            <>
              <Group
                clip={{
                  x: areaProps.x,
                  y: areaProps.y,
                  width: areaProps.width,
                  height: areaProps.height,
                }}
              >
                {imageDesign && (
                  <KonvaImage
                    ref={imageRef}
                    {...tabImages[position].image}
                    image={imageDesign}
                    name="interaction-image"
                    draggable
                    onMouseDown={handleMouseDown('image')}
                    onMouseUp={handleMouseUp}
                    onDragEnd={handleDragEnd('image')}
                    onDragStart={handleDragStart}
                    onDragMove={handleDragMove}
                    onTransform={(e: Konva.KonvaEventObject<Event>) => {
                      e.cancelBubble = true;

                      updateDeleteIconPosition();
                      setSelectedNode('image');
                      setIsOuterAreaActive(true);
                    }}
                    onTransformEnd={handleTransformEnd('image')}
                    onMouseEnter={handleImageMouseEnter}
                    onMouseLeave={handleResetCursor}
                  />
                )}

                {tabImages[position]?.text?.content && (
                  <Text
                    ref={textRef}
                    {...tabImages[position]?.text}
                    text={tabImages[position].text.content}
                    fontSize={16}
                    fill="white"
                    align="center"
                    fontFamily="sans-serif"
                    verticalAlign="middle"
                    draggable
                    name="interaction-text"
                    perfectDrawEnabled={false}
                    onMouseDown={handleMouseDown('text')}
                    onMouseUp={handleMouseUp}
                    onDragStart={handleDragStart}
                    onDragMove={handleDragMove}
                    onDragEnd={handleDragEnd('text')}
                    onTransform={() => {
                      setSelectedNode('text');
                      setIsOuterAreaActive(true);
                      updateDeleteIconPosition();
                    }}
                    onTransformEnd={handleTransformEnd('text')}
                    onMouseEnter={handleImageMouseEnter}
                    onMouseLeave={handleResetCursor}
                  />
                )}
              </Group>

              {isOuterAreaActive && (
                <Group>
                  <Line
                    points={[areaCenter.x, areaProps.y, areaCenter.x, areaProps.y + areaProps.height]}
                    stroke={'#4AFFFF'}
                    strokeWidth={1}
                    dash={!highlightEdges.centerColumn ? [2, 2] : undefined}
                    listening={false}
                  />

                  <Line
                    points={[areaProps.x, areaCenter.y, areaProps.x + areaProps.width, areaCenter.y]}
                    stroke={'#4AFFFF'}
                    strokeWidth={1}
                    dash={!highlightEdges.centerRow ? [2, 2] : undefined}
                    listening={false}
                  />

                  <Line
                    points={[areaProps.x, areaProps.y, areaProps.x + areaProps.width, areaProps.y]}
                    stroke="#4AFFFF"
                    strokeWidth={1}
                    dash={!highlightEdges.top ? [2, 2] : undefined}
                    listening={false}
                  />

                  <Line
                    points={[
                      areaProps.x + areaProps.width,
                      areaProps.y,
                      areaProps.x + areaProps.width,
                      areaProps.y + areaProps.height,
                    ]}
                    stroke="#4AFFFF"
                    strokeWidth={1}
                    dash={!highlightEdges.right ? [2, 2] : undefined}
                    listening={false}
                  />

                  <Line
                    points={[
                      areaProps.x,
                      areaProps.y + areaProps.height,
                      areaProps.x + areaProps.width,
                      areaProps.y + areaProps.height,
                    ]}
                    stroke="#4AFFFF"
                    strokeWidth={1}
                    dash={!highlightEdges.bottom ? [2, 2] : undefined}
                    listening={false}
                  />

                  <Line
                    points={[areaProps.x, areaProps.y, areaProps.x, areaProps.y + areaProps.height]}
                    stroke="#4AFFFF"
                    strokeWidth={1}
                    dash={!highlightEdges.left ? [2, 2] : undefined}
                    listening={false}
                  />

                  <>
                    <Rect
                      x={areaProps.x}
                      y={areaProps.y + areaProps.height + 2}
                      width={areaProps.width}
                      height={18}
                      fill="#3fa3a6"
                      dash={[2, 2]}
                      listening={false}
                    />

                    <Text
                      x={areaProps.x}
                      y={areaProps.y + areaProps.height + 5.5}
                      width={areaProps.width}
                      text="Print area"
                      align="center"
                      verticalAlign="middle"
                      fontSize={12}
                      fill="white"
                      listening={false}
                    />
                  </>
                </Group>
              )}

              {selectedNode && (
                <>
                  <Transformer
                    ref={trRef}
                    flipEnabled={false}
                    centeredScaling
                    keepRatio
                    borderDash={[2.5, 2.5]}
                    borderStrokeWidth={0.5}
                    borderStroke="#4AFFFF"
                    anchorFill="#4AFFFF"
                    anchorStroke="#4AFFFF"
                    anchorSize={selectedNode === 'image' ? 8 : 6}
                    anchorCornerRadius={selectedNode === 'image' ? 6 : 1}
                    rotateAnchorOffset={18}
                    rotationSnaps={[0, 90, 180, 270]}
                    opacity={opacity}
                    enabledAnchors={
                      selectedNode === 'image'
                        ? ['top-left', 'top-right', 'bottom-left', 'bottom-right']
                        : ['middle-left', 'middle-right']
                    }
                    boundBoxFunc={(oldBox, newBox) => {
                      // limit resize
                      if (selectedNode === 'image') {
                        if (Math.abs(newBox.width) < 5 || Math.abs(newBox.height) < 5) {
                          return oldBox;
                        }
                      }

                      if (selectedNode === 'text') {
                        newBox.width = Math.max(30, newBox.width);
                      }

                      return newBox;
                    }}
                  />

                  {/* Icon Delete */}
                  {deleteIconImagePosition && (
                    <Group
                      ref={deleteGroupRef}
                      absolutePosition={deleteIconImagePosition}
                      onMouseEnter={handleDeleteMouseEnter}
                      onMouseLeave={handleResetCursor}
                      opacity={opacity}
                    >
                      <Circle
                        width={selectedNode === 'image' ? 18 : 16}
                        height={selectedNode === 'image' ? 18 : 16}
                        fill="#f14931"
                        onClick={handleDelete}
                      />

                      <Text
                        text="⨯"
                        offsetX={selectedNode === 'image' ? 5.5 : 4.5}
                        offsetY={selectedNode === 'image' ? 14 : 12}
                        fontSize={selectedNode === 'image' ? 24 : 20}
                        fontStyle="bold"
                        fill="#fff9ff"
                        align="center"
                        verticalAlign="middle"
                        fillAfterStrokeEnabled
                        listening={false}
                      />
                    </Group>
                  )}
                </>
              )}
            </>
          )}
        </Layer>
      </Stage>
    </TshirtContainer>
  );
};

export default TabContent;
