import { getRole } from '@app/services/localStorage.service';

export const USER_ROLES_IDS = {
  ADMIN: 'ADMIN',
  SELLER: 'SELLER',
  SUPPORT: 'SUPPORT',
  FACTORY: 'FACTORY',
} as const;

const useCurrentRole = () => {
  const role = getRole() as keyof typeof USER_ROLES_IDS;

  const isAdmin = role === USER_ROLES_IDS.ADMIN;
  const isSeller = role === USER_ROLES_IDS.SELLER;
  const isSupport = role === USER_ROLES_IDS.SUPPORT;
  const isFactory = role === USER_ROLES_IDS.FACTORY;

  return {
    isAdmin,
    isSeller,
    isSupport,
    isFactory,
    role,
  };
};

export default useCurrentRole;
