import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { UploadOutlined, CheckOutlined } from '@ant-design/icons';
import { Upload, InputNumber, Tabs } from 'antd';
import styled from 'styled-components';

import { BaseSelect, Option } from '../common/selects/BaseSelect/BaseSelect';
import { BaseButton } from '../common/BaseButton/BaseButton';

import { containerSize } from '@app/utils/constants';
import { PositionsPositionSlt } from '@app/components/catalog/common';

const ImagesAndPrintingArea = ({ value = [], onChange, positions }) => {
  console.log({ value });

  const [currentTab, setCurrentTab] = useState('');
  const [imagesData, setImagesData] = useState([]);
  const [selectedPosition, setSelectedPosition] = useState('');

  const groupedPositions = useMemo(() => {
    const positionMap = new Map();

    positions.forEach((pos) => {
      const existing = positionMap.get(pos.position);
      const detail = { type: pos.type, surcharge: pos.surcharge };

      if (existing) {
        // Nếu position đã tồn tại, thêm detail vào mảng details
        existing.details.push(detail);
      } else {
        // Nếu position chưa tồn tại, tạo mới
        positionMap.set(pos.position, {
          position: pos.position,
          details: [detail],
        });
      }
    });

    const result = Array.from(positionMap.values());
    return result;
  }, [positions]);

  const availablePositions = useMemo(() => {
    const positionValues = groupedPositions?.map((item) => item.position);

    return positionValues.map((position) => {
      const match = PositionsPositionSlt.find((item) => item.value === position);
      return match || { label: position, value: position };
    });
  }, [groupedPositions]);

  // Đồng bộ selectedPosition khi availablePositions thay đổi
  useEffect(() => {
    if (availablePositions?.length > 0) {
      const isValid = availablePositions.some((item) => item.value === selectedPosition);
      if (!isValid) {
        setSelectedPosition(availablePositions[0].value || '');
      }
    } else {
      setSelectedPosition('');
    }
  }, [availablePositions, selectedPosition]);

  // Đồng bộ giá trị từ Form và reset khi positions rỗng
  useEffect(() => {
    const validPositions = groupedPositions.map((item) => item.position);

    // Nếu positions rỗng, reset imagesData
    if (positions.length === 0) {
      setImagesData([]);
      setCurrentTab('');
      triggerChange([]);
      return;
    }

    const filteredImagesData = value.filter((item) => validPositions.includes(item.position));

    if (JSON.stringify(filteredImagesData) !== JSON.stringify(imagesData)) {
      setImagesData(filteredImagesData);

      if (filteredImagesData.length > 0) {
        if (!validPositions.includes(currentTab)) {
          setCurrentTab(filteredImagesData[0].position);
        }
      } else {
        setCurrentTab('');
      }

      if (filteredImagesData.length !== value.length) {
        onChange?.(filteredImagesData);
      }
    }
  }, [value, positions, groupedPositions, currentTab, onChange, imagesData]);

  const triggerChange = (newImagesData) => {
    // setImagesData(newImagesData);
    if (onChange) {
      onChange(newImagesData);
    }
  };

  const handleUpload = async ({ file, fileList }) => {
    const reader = new FileReader();

    reader.onload = () => {
      const img = new Image();
      img.src = reader.result;

      const areaWidth = img.width * 0.4 || containerSize * 0.4;
      const areaHeight = img.height * 0.5 || containerSize * 0.5;

      const areaLeft = (containerSize - areaWidth) / 2;
      const areaTop = (containerSize - areaHeight) / 2;

      const newImageData = {
        src: reader.result,
        position: selectedPosition,
        file: fileList,
        area: {
          width: areaWidth,
          height: areaHeight,
          top: areaTop,
          left: areaLeft,
        },
      };

      setImagesData((prev) => {
        const existingIndex = prev.findIndex((item) => item.position === selectedPosition);
        let newData;
        if (existingIndex !== -1) {
          newData = [...prev];
          newData[existingIndex] = newImageData;
        } else {
          newData = [...prev, newImageData];
        }
        triggerChange(newData);

        setCurrentTab(selectedPosition);

        // Cập nhật selectedPosition sau khi upload
        const nextAvailable = availablePositions.filter(
          (item) => !newData.some((data) => data.position === item.value),
        );
        setSelectedPosition(nextAvailable[0]?.value || '');
        return newData;
      });
    };

    reader.readAsDataURL(file);
  };

  const updateArea = useCallback((index, field, value) => {
    setImagesData((prev) => {
      const newData = [...prev];
      newData[index].area[field] = value;
      triggerChange(newData);
      return newData;
    });
  }, []);

  const handleRemoveTab = (targetKey) => {
    setImagesData((prev) => {
      const newData = prev.filter((item) => item.position !== targetKey);
      triggerChange(newData);

      // Cập nhật currentTab nếu tab đang active bị xóa
      if (currentTab === targetKey && newData.length > 0) {
        setCurrentTab(newData[0].position);
      } else if (newData.length === 0) {
        setCurrentTab('');
      }

      if (!selectedPosition && availablePositions.length > 0) {
        setSelectedPosition(availablePositions[0].value);
      }

      return newData;
    });
  };

  const handleEditTabs = (key, action) => {
    if (action === 'remove') {
      handleRemoveTab(key);
    }
  };

  const tabItems = useMemo(
    () =>
      imagesData?.map((imageData, index) => {
        return {
          key: imageData.position,
          label: imageData.position,
          children: (
            <div style={{ display: 'flex', flexWrap: 'wrap', gap: '24px' }}>
              <div
                key={index}
                style={{
                  width: '100%',
                  display: 'flex',
                  gap: '100px',
                }}
              >
                <div
                  style={{ position: 'relative', width: containerSize, minWidth: containerSize, height: containerSize }}
                >
                  <img
                    src={imageData.src}
                    alt={`img-${index}`}
                    style={{ width: '100%', height: '100%', objectFit: 'contain' }}
                  />

                  <div
                    style={{
                      position: 'absolute',
                      border: '1.5px dashed #4AFFFF',
                      borderRadius: '4px',
                      width: `${imageData.area.width}px`,
                      height: `${imageData.area.height}px`,
                      top: `${imageData.area.top}px`,
                      left: `${imageData.area.left}px`,
                      pointerEvents: 'none',
                    }}
                  />
                </div>

                {/* Controls điều chỉnh */}
                <div style={{ display: 'flex', flexDirection: 'column', gap: '20px', flex: 1 }}>
                  <div style={{ fontWeight: 'bold' }}>Position: </div>

                  <div style={{ display: 'flex', gap: '20px', alignItems: 'center' }}>
                    <div style={{ minWidth: 60 }}>Width: </div>
                    <InputNumberStyle
                      min={10}
                      max={containerSize}
                      value={imageData.area.width}
                      onChange={(value) => updateArea(index, 'width', value)}
                    />
                  </div>

                  <div style={{ display: 'flex', gap: '20px', alignItems: 'center' }}>
                    <div style={{ minWidth: 60 }}>Height: </div>
                    <InputNumberStyle
                      min={10}
                      max={containerSize}
                      value={imageData.area.height}
                      onChange={(value) => updateArea(index, 'height', value)}
                    />
                  </div>

                  <div style={{ display: 'flex', gap: '20px', alignItems: 'center' }}>
                    <div style={{ minWidth: 60 }}>Top: </div>
                    <InputNumberStyle
                      min={0}
                      max={containerSize - imageData.area.height}
                      value={imageData.area.top}
                      onChange={(value) => updateArea(index, 'top', value)}
                    />
                  </div>

                  <div style={{ display: 'flex', gap: '20px', alignItems: 'center' }}>
                    <div style={{ minWidth: 60 }}>Left: </div>
                    <InputNumberStyle
                      min={0}
                      max={containerSize - imageData.area.width}
                      value={imageData.area.left}
                      onChange={(value) => updateArea(index, 'left', value)}
                      width={'100%'}
                    />
                  </div>
                </div>
              </div>
            </div>
          ),
        };
      }),
    [imagesData],
  );

  const handleChangeSelect = (value) => {
    setSelectedPosition(value);
  };

  const handleTabChange = (key) => {
    setCurrentTab(key);
  };

  const renderOption = (option) => {
    const isSelected = imagesData?.some((item) => item.position === option.value);
    return (
      <Option key={option.value} value={option.value}>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            fontWeight: isSelected ? 'bold' : 'normal',
            color: isSelected ? '#1890ff' : '#000',
          }}
        >
          {option.label}
          {isSelected && <CheckOutlined style={{ marginLeft: 8, color: '#52c41a' }} />}
        </div>
      </Option>
    );
  };

  return (
    <div style={{ width: '100%', display: 'flex', flexDirection: 'column', gap: '20px' }}>
      <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
        <BaseSelect
          onChange={handleChangeSelect}
          value={selectedPosition}
          height={36}
          size="small"
          width={500}
          style={{ minWidth: '6.25rem' }}
          getPopupContainer={() => document.body}
          dropdownStyle={{ minWidth: 'max-content' }}
        >
          {availablePositions?.map((item) => renderOption(item))}
        </BaseSelect>

        <Upload
          name="file"
          accept=".jpg, .jpeg, .png"
          showUploadList={false}
          beforeUpload={() => {
            return false;
          }}
          onChange={(info) => handleUpload(info)}
          maxCount={1}
        >
          <BaseButton type="ghost" icon={<UploadOutlined />}>
            Upload Image
          </BaseButton>
        </Upload>
      </div>

      {imagesData?.length > 0 && (
        <Tabs
          hideAdd
          animated
          tabPosition="top"
          type="editable-card"
          activeKey={currentTab}
          onChange={handleTabChange}
          items={tabItems}
          onEdit={handleEditTabs}
        />
      )}
    </div>
  );
};

const InputNumberStyle = styled(InputNumber)`
  width: 300px;
`;

export default ImagesAndPrintingArea;
